import { gql } from "@apollo/client";
import GraphQlService from "./graphql";

class VideoGraphql extends GraphQlService {
  async createVideoRoom(data) {
    return this.client
      .mutate({
        mutation: gql`
          mutation CreateVideoRoom($input: CreateVideoRoomInput!) {
            createVideoRoom(createVideoRoomInput: $input) {
              id
              accountId
              eventId
              expiresAt
              externalUserId
            }
          }
        `,
        variables: {
          input: data,
        },
      })
      .then((result) => result?.data?.createVideoRoom);
  }
}

const videoGraphqlInstance = new VideoGraphql();
export default videoGraphqlInstance;
