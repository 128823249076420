import * as React from "react";

const ReportIcon = ({ width = 30, height = 30, ...props }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 185 185"
    stroke="#a39c8f"
    strokeWidth="4"
    {...props}
  >
    <g>
      <g>
        <g>
          <path
            d="M129.318,0H26.06c-1.919,0-3.475,1.554-3.475,3.475v177.203c0,1.92,1.556,3.475,3.475,3.475h132.034
				c1.919,0,3.475-1.554,3.475-3.475V34.131C161.568,22.011,140.771,0,129.318,0z M154.62,177.203H29.535V6.949h99.784
				c7.803,0,25.301,18.798,25.301,27.182V177.203z"
          />
          <path
            d="M71.23,76.441c15.327,0,27.797-12.47,27.797-27.797c0-15.327-12.47-27.797-27.797-27.797
				c-15.327,0-27.797,12.47-27.797,27.797C43.433,63.971,55.902,76.441,71.23,76.441z M71.229,27.797
				c11.497,0,20.848,9.351,20.848,20.847c0,0.888-0.074,1.758-0.183,2.617l-18.071-2.708L62.505,29.735
				C65.162,28.503,68.112,27.797,71.229,27.797z M56.761,33.668l11.951,19.869c0.534,0.889,1.437,1.49,2.462,1.646l18.669,2.799
				c-3.433,6.814-10.477,11.51-18.613,11.51c-11.496,0-20.847-9.351-20.847-20.847C50.381,42.767,52.836,37.461,56.761,33.668z"
          />
          <rect x="46.907" y="90.339" width="73.058" height="6.949" />
          <rect x="46.907" y="107.712" width="48.644" height="6.949" />
          <rect x="46.907" y="125.085" width="62.542" height="6.949" />
        </g>
      </g>
    </g>
  </svg>
);

export default ReportIcon;
