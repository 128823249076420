import { gql } from "@apollo/client";
import GraphQlService from "./graphql";

class ChatGraphql extends GraphQlService {
  async removeGroup(groupId) {
    return this.client.mutate({
      mutation: gql`
            mutation removeChatChatRoom{
              removeChatChatRoom(
                id: "${groupId}",
              )
              {
                response{
                  messages{
                    message
                  }
                }
              }
              }
            `,
    });
  }

  async updateGroupDetails(groupId, groupName, participants) {
    let participantsFormated = "";
    participants.forEach((attachment) => {
      if (attachment.action === "REMOVE") {
        participantsFormated += `{ id: "${attachment.id}", action: "${attachment.action}"}`;
      } else {
        participantsFormated += `{ userId: "${attachment.userId}", action: "${attachment.action}", name: "${attachment.name}"}`;
      }
    });

    return this.client.mutate({
      mutation: gql`
        mutation updateChatChatRoom{
          updateChatChatRoom(
            updateChatChatRoomInput: {
              id: "${groupId}"
              name: "${groupName}"
              participants: [${participantsFormated}]
            }
          )
          {
            name
            chatChatParticipants{
              name
              userId
            }
          }
        }
      `,
    });
  }

  async getUnreadMessageCount(id, accountId, userId) {
    return this.client
      .query({
        query: gql`
          query{
            getUnreadMessageCount(getUnreadMessageCountInput:{
              id:"${id}"
              accountId:"${accountId}"
              userId:"${userId}"
            })
          }
        `,
      })
      .then((result) => result?.data?.getUnreadMessageCount);
  }

  async getUnreadMessageCountForGlobal(id, userId) {
    return this.client
      .query({
        query: gql`
          query{
            getUnreadMessageCountForGlobal(getUnreadMessageCountForGlobalInput:{
              id:"${id}"
              userId:"${userId}"
            })
          }
        `,
      })
      .then((result) => result?.data?.getUnreadMessageCountForGlobal);
  }

  async removeChatChatMessage(data) {
    return this.client.mutate({
      mutation: gql`
          mutation{
            removeChatChatMessage(deleteChatChatMessageInput:{
              id:"${data.id}"
              type:"${data.type}"
              isLastInGroup:${data.isLastInGroup}
              chatRoomId:"${data.chatRoomId}"
            })
          }
        `,
    });
  }

  async createChatChatRoomGlobal(data) {
    return this.client
      .mutate({
        mutation: gql`
          mutation{
            createChatChatRoomGlobal(createChatChatRoomGlobalInput:{
              isArchived:${data.isArchived},
              organizerId:"${data.organizerId}",
              organizerName:"${data.organizerName}",
              name: ${data.name ? `"${data.name}"` : null} 
            }){
              id
            }
          }
        `,
      })
      .then((result) => result.data.createChatChatRoomGlobal);
  }

  async createChatChatParticipantGlobal(data) {
    return this.client
      .mutate({
        mutation: gql`
          mutation {
            createChatChatParticipantGlobal(
              createChatChatParticipantGlobalInput: {
                userId: "${data.userId}"
                globalChatRoomId: "${data.globalChatRoomId}"
                name: ${data.name ? `"${data.name}"` : null} 
              }
            ) {
              id
            }
          }
        `,
      })
      .then((result) => result.data.createChatChatParticipantGlobal);
  }

  async getChatRoomsByUserId(data) {
    return this.client
      .query({
        query: gql`
        query{
          getChatRoomsByUserId(
               userId:"${data.userId}"
               populateLastMessage:${data.populateLastMessage}
               populateChatParticipants:${data.populateChatParticipants}
            ){
            id
            name
            isArchived
            organizerId
            organizerName
            lastMessage{
              id
              globalChatRoomId
              senderId
              senderName
              messageText
              sentAt
              attachmentName
              type
              createdAt
              updatedAt
              createdBy
              updatedBy
              version
            }
            messageCount
            createdAt
            createdBy
            updatedAt
            updatedBy
            version
            participants{
              id
              globalChatRoomId
              userId
              name
              sendMessageCount
              readMessageCount
              createdAt
              createdBy
              updatedAt
              updatedBy
              version
            }
          }
        }`,
      })
      .then((result) => result.data.getChatRoomsByUserId);
  }

  async updateChatChatRoomGlobal(data) {
    return this.client
      .mutate({
        mutation: gql`
        mutation {
          updateChatChatRoomGlobal(
            updateChatChatRoomGlobalInput: {
              id: "${data.id}"
              isArchived: ${data.isArchived}
            }
          ) {
            id
            isArchived
            organizerName
          }
        }
      `,
      })
      .then((result) => result.data.updateChatChatRoomGlobal);
  }

  async getChatRoomMessages(chatRoomId, date=null) {
    return this.client
      .query({
        query: gql`
        query{
          getByGlobalChatRoomId(
            globalChatRoomId:"${chatRoomId}"
            date: ${date ? `"${date}"` : null}
            ){
            id
            isAnonymous
            globalChatRoomId
            senderId
            senderName
            messageText
            sentAt
            attachmentName
            type
            createdAt
            createdBy
            updatedAt
            updatedBy
            version
          }
        }
      `,
      })
      .then((result) => result.data.getByGlobalChatRoomId);
  }

  async createChatChatMessageGlobal(data) {
    return this.client
      .mutate({
        mutation: gql`
        mutation {
          createChatChatMessageGlobal(
            createChatChatMessageGlobalInput: {
              id: "${data.id}"
              globalChatRoomId: "${data.globalChatRoomId}"
              senderId: "${data.senderId}"
              senderName: "${data.senderName}"
              messageText: """${data.messageText}"""
              sentAt:${data.sentAt}
              attachmentName: ${
                data.attachmentName ? `"${data.attachmentName}"` : null
              } 
              type: "${data.type}"
            }
          ) {
            id
            globalChatRoomId
            senderId
            senderName
            messageText
            sentAt
            attachmentName
            type
            createdAt
            createdBy
            updatedAt
            updatedBy
            version
          }
        }
      `,
      })
      .then((result) => result.data.createChatChatMessageGlobal);
  }

  async removeGlobalChatChatMessage(data) {
    return this.client.mutate({
      mutation: gql`
          mutation{
            removeGlobalChatChatMessage(deleteChatChatMessageGlobalInput:{
              id:"${data.id}"
              type:"${data.type}"
              isLastInGroup:${data.isLastInGroup}
              globalChatRoomId:"${data.globalChatRoomId}"
            })
          }
        `,
    });
  }

  async getAttachmentsByGlobalChatRoomId(globalChatRoomId) {
    return this.client
      .query({
        query: gql`
        query{
          getAttachmentsByGlobalChatRoomId(globalChatRoomId:"${globalChatRoomId}"){
            globalChatRoomId
            attachmentId
            attachmentName
            attachmentSize
            attachmentType
            contentType
            entityType
            sentAt
            createdAt
            createdBy
            updatedAt
            updatedBy
            version
          }
        }
      `,
      })
      .then((result) => result.data.getAttachmentsByGlobalChatRoomId);
  }

  async getGlobalChatChatRoomForAdmin(){
    return this.client
    .query({
      query: gql`
        query{
          getGlobalChatChatRoomForAdmin{
            id
            accountId
            organizerId
            organizerName
            lastMessage
            messageCount
            createdAt
            createdBy
            updatedAt
            updatedBy
            chatChatParticipants{
              id
              accountId
              name
              userId
              chatRoomId
              sendMessageCount
              readMessageCount
            }
          }
        }
      `,
    }).then((result) => result.data.getGlobalChatChatRoomForAdmin);
  }
}

const chatGraphqlInstance = new ChatGraphql();
export default chatGraphqlInstance;
