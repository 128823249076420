import * as React from 'react';
import clsx from 'clsx';

function DotCircle({className, ...props}) {
  return (
    <svg
      className={clsx(className || 'w-8 h-8')}
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#prefix__filter0_d_1967:26545)">
        <circle cx={16} cy={12} r={6} fill="#40B284" />
        <circle cx={16} cy={12} r={4.5} stroke="#fff" strokeWidth={3} />
      </g>
      <defs>
        <filter
          id="prefix__filter0_d_1967:26545"
          x={0}
          y={0}
          width={32}
          height={32}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={5} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1967:26545"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1967:26545"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default DotCircle;
