import * as React from 'react';
import clsx from 'clsx';

const DisableIcon = ({className, ...props}) => (
  <svg
    className={clsx('w-5 h-5', className)}
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={8.999} cy={9.761} r={8} stroke="#EE6A5F" strokeWidth={1.6} />
    <path
      fill="#EE6A5F"
      d="m3.043 5.057 1.241-1.248 10.767 10.707-1.241 1.248z"
    />
  </svg>
);

export default DisableIcon;
