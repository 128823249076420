import clsx from 'clsx';

function NoteIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-6 h-6', className)}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M12 21.8571C17.4439 21.8571 21.8571 17.4439 21.8571 12C21.8571 6.55605 17.4439 2.14286 12 2.14286C6.55605 2.14286 2.14286 6.55605 2.14286 12C2.14286 17.4439 6.55605 21.8571 12 21.8571ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12 10.9286C12.5917 10.9286 13.0714 11.4083 13.0714 12V17.1429C13.0714 17.7346 12.5917 18.2143 12 18.2143C11.4083 18.2143 10.9286 17.7346 10.9286 17.1429V12C10.9286 11.4083 11.4083 10.9286 12 10.9286Z" />
      <path d="M13.0714 7.92857C13.0714 8.52031 12.5917 9 12 9C11.4083 9 10.9286 8.52031 10.9286 7.92857C10.9286 7.33684 11.4083 6.85714 12 6.85714C12.5917 6.85714 13.0714 7.33684 13.0714 7.92857Z" />
    </svg>

  );
}

export default NoteIcon;
