import {
  createContext, useEffect, useRef, useContext, useState,
} from 'react';
import {io} from 'socket.io-client';

import config from '@config/config';
import LocalStorageService from '@services/localStorage';
import {USER_ROLES} from '@utils/consts';
import {UserContext} from './User';

export const ChatContext = createContext({
  socket: null,
  openedChatRoomId: undefined,
  setOpenedChatRoomId: () => {},
});

const ChatProvider = ({children}) => {
  const {profile: currentProfile} = useContext(UserContext);
  const [openedChatRoomId, setOpenedChatRoomId] = useState();
  const socket = useRef();

  useEffect(() => {
    if (!currentProfile
      || (currentProfile.type !== USER_ROLES.STUDENT_ROLE
        && currentProfile.type !== USER_ROLES.COUNSELLOR_ROLE)
         || (socket.current && socket.current.connected)) {
      return undefined;
    }

    const accessToken = LocalStorageService.getAccessToken();

    if (!accessToken) {
      return undefined;
    }

    socket.current = io(config.chatRoomSocketDomain, {
      path: config.chatRoomSocketPath,
      query: {
        accessToken,
      },
    });

    return () => {
      if (socket?.current && socket.current.connected) {
        socket.current.disconnect();
      }
     };
  }, [currentProfile?.id]);

  const value = {socket, openedChatRoomId, setOpenedChatRoomId};

  return (
    <ChatContext.Provider value={{...value}}>{children}</ChatContext.Provider>
  );
};

export default ChatProvider;
