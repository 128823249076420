import React from 'react';
import clsx from 'clsx';

import PropTypes from 'prop-types';
import ReactPopup from 'reactjs-popup';

import styles from './Popup.module.scss';
import './Popup.scss';

const Popup = ({
  variant,
  children,
  trigger,
  containerSelector,
  position,
  className,
  closeOnContentClick,
  onOpen,
  onClose,
  offsetY,
  offsetX,
  isOverflowEnabled = false,
}) => {
  const onContentClick = (closeFn) => {
    if (closeOnContentClick) {
      closeFn();
    }
  };

  return (
    <ReactPopup
      className={clsx(variant, className)}
      trigger={trigger}
      keepTooltipInside={containerSelector}
      position={position}
      on="click"
      onClose={onClose}
      onOpen={onOpen}
      offsetY={offsetY}
      offsetX={offsetX}
    >
      {(close) => (
        <div className={clsx(styles.popupContent, isOverflowEnabled ? 'overflow-visible' : 'overflow-hidden')} onClick={() => onContentClick(close)}>
          {React.cloneElement(children, {closePopup: close})}
        </div>
      )}
    </ReactPopup>
  );
};

Popup.propTypes = {
  variant: PropTypes.oneOf(['default', 'navbar', 'accounts', 'pdf', 'csv']),
  children: PropTypes.node.isRequired,
  trigger: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  className: PropTypes.string,
  closeOnContentClick: PropTypes.bool,
  containerSelector: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  offsetY: PropTypes.number,
  offsetX: PropTypes.number,
};

Popup.defaultProps = {
  variant: 'default',
  className: '',
  closeOnContentClick: true,
  containerSelector: null,
  position: 'bottom left',
  onOpen: () => {},
  onClose: () => {},
  offsetY: 0,
  offsetX: 0,
};

export default Popup;
