import * as React from 'react';

const SvgComponent = (props) => (
  <svg
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={11.501}
      cy={11.499}
      r={9.583}
      stroke={props.color ? props.color : "#585550"}
      strokeWidth={1.8}
    />
    <path
      d="M11.5 6.229v5.27l2.875 2.875"
      stroke={props.color ? props.color : "#585550"}
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
