import Auth from '@admin/pages/Auth';
import Root from '@admin/pages/Root';
import {ManyUsers, SettingsNavbar, User,Statistics, SecureIcon} from '@assets/icons';
import ChatIcon from '@components/Navbar/ChatIcon';
import {
  ADMIN_HOME,
  ADMIN_SETTINGS,
  ADMIN_SUPER_ACTION,
  ADMIN_SIGN_IN,
  ADD_ORGANIZATION,
  EDIT_ORGANIZATION,
  ADMIN_COUNSELLORS,
  ADMIN_ADD_COUNSELLORS,
  ADMIN_VIEW_COUNSELLOR,
  ADMIN_EDIT_COUNSELLORS,
  ADMIN_DATA_MIGRATION,
  ADMIN_CONFIG_DATA,
  ADMIN_CONFIG_FORM,
  ADMIN_VIEW_ORGANIZATION_CONFIG,
  ADMIN_EDIT_CONFIG_FORM,
  ADMIN_STATISTIC,
  ADMIN_NOTIFICATIONS,
  ADMIN_CHECK_INS_AND_SURVEYS,
  ADMIN_CREATE_SURVEY_TEMPLATE,
  ADMIN_EDIT_SURVEY_TEMPLATE,
  ADMIN_CREATE_CONFIG_TEMPLATE,
  ADMIN_EDIT_CONFIG_TEMPLATE,
  ADMIN_SURVEYS_TEMPLATE_STATISTICS,
  ADMIN_BROADCAST_MESSAGES,
  ADMIN_MESSAGES
} from './consts';
import TemplateIcon from '@assets/icons/Template';
import BroadcastIcon from '@assets/icons/BroadcastIcon';

export const ADMIN_AUTH_ROUTES = [
  {
    name: 'signIn',
    path: ADMIN_SIGN_IN,
    exact: true,
    element: <Auth.SignIn/>,
  },
];

export const ADMIN_ROOT_ROUTES = [
  {
    name: 'statistics',
    path: ADMIN_STATISTIC,
    icon: Statistics,
    exact: true,
    isMenu: true,
    element: <Root.Statistics/>,
  },
  {
    name: 'organizations',
    path: ADMIN_HOME,
    icon: ManyUsers,
    exact: true,
    isMenu: true,
    element: <Root.Organizations/>,
  },
  {
    name: 'addOrganization',
    path: ADD_ORGANIZATION,
    exact: true,
    element: <Root.OrganizationManagement/>,
  },
  {
    name: 'editOrganization',
    path: EDIT_ORGANIZATION,
    exact: true,
    element: <Root.OrganizationManagement/>,
  },
  {
    name: 'viewOrganization',
    path: `${ADMIN_HOME}/:id`,
    exact: true,
    element: <Root.Organization/>,
  },
  {
    name: 'counsellors',
    path: ADMIN_COUNSELLORS,
    exact: true,
    isMenu: true,
    icon: User,
    element: <Root.Counsellors/>,
  },
  {
    name: 'dataMigration',
    path: ADMIN_DATA_MIGRATION,
    exact: true,
    isMenu: true,
    icon: User,
    element: <Root.dataMigration/>,
  },
  {
    name: 'configData',
    path: ADMIN_CONFIG_DATA,
    exact: true,
    element: <Root.configDataTable/>,
  },
  {
    name: 'configForm',
    path: ADMIN_CONFIG_FORM,
    exact: true,
    element: <Root.configForm/>,
  },
  {
    name: 'editConfigForm',
    path: ADMIN_EDIT_CONFIG_FORM,
    exact: true,
    element: <Root.editConfigForm/>,
  },
  {
    name: 'organizationConfig',
    path: ADMIN_VIEW_ORGANIZATION_CONFIG,
    exact: true,
    element: <Root.organizationConfig/>,
  },
  {
    name: 'settings',
    path: ADMIN_SETTINGS,
    exact: true,
    isMenu: true,
    icon: SettingsNavbar,
    element: <Root.Settings/>,
  },
  {
    name: 'notifications',
    path: ADMIN_NOTIFICATIONS,
    icon: ChatIcon,
    exact: true,
    isMenu: true,
    element: <Root.Notifications/>,
  },
  {
    name: 'addCounsellor',
    path: ADMIN_ADD_COUNSELLORS,
    exact: true,
    element: <Root.CounsellorsManagement/>,
  },
  {
    name: 'viewCounsellor',
    path: ADMIN_VIEW_COUNSELLOR,
    exact: true,
    element: <Root.CounsellorView/>,
  },
  {
    name: 'editCounsellor',
    path: ADMIN_EDIT_COUNSELLORS,
    exact: true,
    element: <Root.CounsellorsManagement/>,
  },
  {
    name: 'superActions',
    path: ADMIN_SUPER_ACTION,
    exact: true,
    isMenu: true,
    icon: SecureIcon,
    element: <Root.SuperActions/>,
  },
  {
    name: 'checkInsAndSurveys',
    path: ADMIN_CHECK_INS_AND_SURVEYS,
    params: {template: 'template'},
    exact: true,
    isMenu: true,
    icon: TemplateIcon,
    element: <Root.CheckInsAndSurveys/>,
  },
  {
    name: 'createSurveyTemplate',
    path: ADMIN_CREATE_SURVEY_TEMPLATE,
    exact: true,
    isMenu: false,
    element: <Root.CreateSurveyTemplate/>,
  },
  {
    name: 'editSurveyTemplate',
    path: ADMIN_EDIT_SURVEY_TEMPLATE,
    exact: true,
    isMenu: false,
    element: <Root.CreateSurveyTemplate/>,
  },
  {
    name: 'createConfigTemplate',
    path: ADMIN_CREATE_CONFIG_TEMPLATE,
    exact: true,
    isMenu: false,
    element: <Root.CreateConfigTemplate/>,
  },
  {
    name: 'editConfigTemplate',
    path: ADMIN_EDIT_CONFIG_TEMPLATE,
    exact: true,
    isMenu: false,
    element: <Root.CreateConfigTemplate/>,
  },
  {
    name: 'surveyTemplateStatistics',
    path: ADMIN_SURVEYS_TEMPLATE_STATISTICS,
    exact: true,
    isMenu: false,
    element: <Root.SurveysTemplateStatistics/>,
  },
  {
    name:'broadcastMessages',
    path:ADMIN_BROADCAST_MESSAGES,
    exact:true,
    isMenu:true,
    icon:BroadcastIcon,
    element:<Root.BroadcastMessages/>,
  },
  {
    name: 'messages',
    path: ADMIN_MESSAGES,
    exact: true,
    isMenu: true,
    icon: ChatIcon,
    element: <Root.Messages/>,
  }
];
