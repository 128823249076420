import * as React from 'react';

const SvgComponent = (props) => (
  <svg
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.506 1.43A5.187 5.187 0 0 0 17.894 0a5.196 5.196 0 0 0-3.611 1.43L1.859 13.316a3.04 3.04 0 0 0-.907 1.684L.02 20.345c-.079.452.076.915.415 1.239a1.524 1.524 0 0 0 1.296.397l5.589-.892a3.273 3.273 0 0 0 1.76-.866L21.503 8.339C22.468 7.416 23 6.189 23 4.884a4.74 4.74 0 0 0-1.494-3.453ZM7.832 19.028c-.22.21-.495.345-.8.394l-5.199.828.866-4.972a1.38 1.38 0 0 1 .412-.765l9.533-9.12 4.72 4.515-9.532 9.12ZM20.255 7.143l-1.64 1.568-4.72-4.515 1.64-1.568a3.397 3.397 0 0 1 2.36-.936c.892 0 1.73.332 2.36.936.63.603.978 1.404.978 2.257 0 .853-.348 1.654-.978 2.258Z"
      fill="#857F78"
    />
  </svg>
);

export default SvgComponent;
