import {
  EDIT_PROFILE, PROFILE, SETTINGS, VIDEO_ROOM,
} from '@router/consts';
import Root from '@pages/Root';

export const ROOT_ROUTES = [
  {
    name: 'profile',
    path: `${PROFILE}/:id`,
    exact: true,
    element: <Root.Profile/>,
  },
  {
    name: 'editProfile',
    path: EDIT_PROFILE,
    element: <Root.EditProfile/>,
  },
  {
    name: 'settings',
    path: SETTINGS,
    exact: true,
    Icon: null,
    element: <Root.Settings/>,
  },
  {
    name: 'videoRoom',
    path: VIDEO_ROOM,
    element: <Root.VideoRoom/>,
  },
];
