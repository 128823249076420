import loadable from '@loadable/component';
import {loadableRootParams} from '@components/Skeleton/loadableParams';

const Root = {
  Statistics: loadable(async () => import('./Statistics'), loadableRootParams),
  Organizations: loadable(async () => import('./Organizations'), loadableRootParams),
  Organization: loadable(async () => import('./Organization'), loadableRootParams),
  OrganizationManagement: loadable(async () => import('./OrganizationManagement'), loadableRootParams),
  Settings: loadable(async () => import('./Settings'), loadableRootParams),
  SuperActions: loadable(async () => import('./SuperActions'), loadableRootParams),
  Counsellors: loadable(async () => import('./allbryCounsellors'), loadableRootParams),
  dataMigration: loadable(async () => import('./DataMigration/FtpDataCollection'), loadableRootParams),
  configDataTable: loadable(async () => import('./DataMigration/ConfigData/ConfigDataTable'), loadableRootParams),
  configForm: loadable(async () => import('./DataMigration/ConfigData/ConfigForm'), loadableRootParams),
  editConfigForm: loadable(async () => import('./DataMigration/ConfigData/ConfigForm'), loadableRootParams),
  organizationConfig: loadable(async () => import('./DataMigration/ConfigData/OrganizationConfigView'), loadableRootParams),
  CounsellorView: loadable(async () => import('./allbryCounsellorView'), loadableRootParams),
  CounsellorsManagement: loadable(async () => import('./allbryCounsellorsManagement'), loadableRootParams),
  Notifications: loadable(async () => import('./Notifications'), loadableRootParams),

  CheckInsAndSurveys: loadable(async () => import('./CheckInsAndSurveys'), loadableRootParams),
  CreateSurveyTemplate: loadable(async () => import('./CheckInsAndSurveys/CreateTemplate'), loadableRootParams),
  CreateConfigTemplate: loadable(async () => import('./CheckInsAndSurveys/Config/CreateConfig'), loadableRootParams),
  SurveysTemplateStatistics: loadable(async () => import('./CheckInsAndSurveys/Statistics'), loadableRootParams),
  BroadcastMessages: loadable(async () => import('./BroadcastMessages'), loadableRootParams),
  Messages: loadable(async () => import('./Messages'), loadableRootParams),
};

export default Root;
