import clsx from 'clsx';

function ArrowLeftIcon({className, ...props}) {
  return (
    <svg className={clsx('w-3 h-5', className)} viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M9.0498 16.9201L2.5298 10.4001C1.7598 9.63008 1.7598 8.37008 2.5298 7.60008L9.0498 1.08008" stroke="#585550" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}

export default ArrowLeftIcon;
