import * as React from 'react';
import clsx from 'clsx';

function UserIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-5 h-6', className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 24"
      {...props}
    >
      <path
        d="M10.499 12.923c3.922 0 7.114-2.899 7.114-6.462C17.613 2.898 14.42 0 10.498 0 6.576 0 3.385 2.898 3.385 6.461s3.19 6.462 7.114 6.462zm0-11.077c2.802 0 5.08 2.07 5.08 4.615 0 2.546-2.278 4.616-5.08 4.616-2.803 0-5.082-2.07-5.082-4.616 0-2.545 2.28-4.615 5.082-4.615zM21 19.344v1.244c0 1.15-.462 2.253-1.302 3.104-.2.204-.479.307-.758.307-.24 0-.483-.077-.677-.235a.865.865 0 01-.079-1.303 2.662 2.662 0 00.785-1.872v-1.244c0-1.864-1.36-3.476-3.31-3.92a1.507 1.507 0 00-1.011.118c-2.576 1.302-5.728 1.297-8.288.004a1.49 1.49 0 00-1.017-.123c-1.95.445-3.312 2.057-3.312 3.92v1.245c0 .695.28 1.36.785 1.872a.866.866 0 01-.079 1.303c-.42.341-1.062.308-1.435-.071C.462 22.84 0 21.738 0 20.589v-1.244c0-2.71 1.993-5.059 4.847-5.71a3.727 3.727 0 012.512.304c1.938.98 4.339.984 6.293-.003.741-.384 1.658-.494 2.505-.3 2.85.648 4.843 2.996 4.843 5.708z"
      />
    </svg>
  );
}

export default UserIcon;
