import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {AnimatePresence, motion} from 'framer-motion';

import styles from './ButtonGroupItem.module.scss';

const ButtonGroupItem = ({
  label, isChecked, onClick, variant, variantSelected, animationDelay,
}) => {
  const {t} = useTranslation();
  const selectedStyle = variantSelected ? styles[variantSelected] : styles.selected;

  return (
    <AnimatePresence>
      <motion.div
        onClick={onClick}
        className={isChecked ? selectedStyle : styles[variant]}
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{duration: 0.5, delay: animationDelay}}
      >
        {t(label)}
      </motion.div>
    </AnimatePresence>
  );
};

ButtonGroupItem.propTypes = {
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'report']),
  variantSelected: PropTypes.oneOf(['selected', 'reportSelected']),
  animationDelay: PropTypes.number,
};

ButtonGroupItem.defaultProps = {
  label: '',
  isChecked: false,
  onClick: () => {},
  variant: 'default',
  variantSelected: 'selected',
  animationDelay: 0,
};

export default ButtonGroupItem;
