import { createMongoAbility } from "@casl/ability";
import { AbilityBuilder } from '@casl/ability';

export const ability = new createMongoAbility();

export function convertAbility(data) {
    const { can, rules } = new AbilityBuilder();
    data.forEach((m) => {
        can(m.roleFeatures.code, m.customRoles.masterRoles.code)
    });
    return rules;
}