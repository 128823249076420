export const internalReportOptions = [
  {label: 'counsellorReport.internalReport.meetingTypes.type1', value: 'EHT', color: '#FF7F50'},
  {label: 'counsellorReport.internalReport.meetingTypes.type2', value: 'EHM', color: '#AFB'},
  {label: 'counsellorReport.internalReport.meetingTypes.type3', value: 'Cooperation meeting SIP', color: '#F08080'},
  {label: 'counsellorReport.internalReport.meetingTypes.type4', value: 'Cooperation meeting with VH, students & other actors', color: '#E9967A'},
  {label: 'counsellorReport.internalReport.meetingTypes.type5', value: 'Cooperation meeting BUP', color: '#AFEEEE'},
  {label: 'counsellorReport.internalReport.meetingTypes.type6', value: 'Meeting with VH, students & other actors', color: '#FFA07A'},
  {label: 'counsellorReport.internalReport.meetingTypes.type7', value: 'Meeting with student and VH', color: '#B8860B'},
  {label: 'counsellorReport.internalReport.meetingTypes.type8', value: 'Meeting with VH', color: '#DAA520'},
  {label: 'counsellorReport.internalReport.meetingTypes.type9', value: 'Meeting with student and school staff', color: '#EEE8AA'},
  {label: 'counsellorReport.internalReport.meetingTypes.type10', value: 'Meeting with VH and another actor', color: '#BDB76B'},
  {label: 'counsellorReport.internalReport.meetingTypes.type11', value: 'Meeting cooperation', color: '#F0E68C'},
  {label: 'counsellorReport.internalReport.meetingTypes.type12', value: 'Attendance work', color: '#808000'},
  {label: 'counsellorReport.internalReport.meetingTypes.type13', value: 'Guiding school staff', color: '#9ACD32'},
  {label: 'counsellorReport.internalReport.meetingTypes.type14', value: 'Meeting with school staff', color: '#556B2F'},
  {label: 'counsellorReport.internalReport.meetingTypes.type15', value: 'Meeting with principal', color: '#6B8E23'},
  {label: 'counsellorReport.internalReport.meetingTypes.type16', value: 'Participation in development work', color: '#008000'},
  {label: 'counsellorReport.internalReport.meetingTypes.type17', value: 'Participation in security work', color: '#228B22'},
  {label: 'counsellorReport.internalReport.meetingTypes.type18', value: 'Work with student groups', color: '#FF7F70'},
];

export const staticAttributes = [
  {
    attributeName: {label: 'counsellorReport.internalReport.staticAttributes.attributeName.attribute1', value: 'Stage'},
    attributeValue: [
      {label: 'counsellorReport.internalReport.staticAttributes.attributeValue.attribute1value1', value: 'Low stage'},
      {label: 'counsellorReport.internalReport.staticAttributes.attributeValue.attribute1value2', value: 'Middle stage'},
      {label: 'counsellorReport.internalReport.staticAttributes.attributeValue.attribute1value3', value: 'High stage'},
      {label: 'counsellorReport.internalReport.staticAttributes.attributeValue.attribute1value4', value: 'High school'},
    ],
  },
];
