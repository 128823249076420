import { gql } from "@apollo/client";
import GraphQlService from "./graphql";

class CalendarGraphql extends GraphQlService {
  async getUnAnsweredEvents(users = []) {
    return this.client
      .query({
        query: gql`
              query {
                getUnAnsweredEvents(userIds: ${JSON.stringify(users)}){
                    id
                    accountId
                }
            }
              `,
        fetchPolicy: "network-only",
      })
      .then((result) => result?.data?.getUnAnsweredEvents);
  }
  async updateCalendarSyncStatus(status) {
    return this.client
      .mutate({
        mutation: gql`
        mutation {
          updateCalendarSyncStatus(
            status: ${status}
          ){
            id
            accountId
            name
            type
            ownerId
            createdAt
            updatedAt
            createdBy
            updatedBy
            version
            externalSync
            externalSyncFreeTime
          }   
        }
        `,
      })
      .then((result) => result?.data?.updateCalendarSyncStatus);
  }

  async getAllCalenderEventDatesById(calendarId, startDate, endDate) {
    return this.client
      .query({
        query: gql`
            query{
              getAllCalenderEventDatesById(getAllCalenderEventDatesByIdInput:{
                id: "${calendarId}"
                startDate: "${startDate}"
                EndDate: "${endDate}"
              })
            }
          `,
      })
      .then((result) => result?.data?.getAllCalenderEventDatesById);
  }

  async getByGlobalCalendarEventId(calendarId) {
    return this.client
      .query({
        query: gql`
            query {
              getByGlobalCalendarEventId(
                globalCalendarEventId: "${calendarId}"
              ) {
                id
              }
            }
          `,
      })
      .then((result) => result?.data?.getByGlobalCalendarEventId);
  }

  async updateCalendarSyncFreeTimeStatus(status) {
    return this.client
      .mutate({
        mutation: gql`
        mutation {
          updateCalendarSyncFreeTimeStatus(
            status: ${status}
          ){
            id
            accountId
            name
            type
            ownerId
            createdAt
            updatedAt
            createdBy
            updatedBy
            version
            externalSync
            externalSyncFreeTime
          }   
        }
        `,
      })
      .then((result) => result?.data?.updateCalendarSyncFreeTimeStatus);
  }

  async createManyCalendarEventExternalUser(users) {
    return this.client
      .mutate({
        mutation: gql`
          mutation CreateManyCalendarEventExternalUser(
            $users: [CreateCalendarEventExternalUserInput!]!
          ) {
            createManyCalendarEventExternalUser(
              createManyCalendarEventExternalUserInput: $users
            ) {
              id
              name
            }
          }
        `,
        variables: {
          users,
        },
      })
      .then((result) => result?.data?.createManyCalendarEventExternalUser);
  }

  async getExternalUserByExternalCode(externalCode) {
    return this.client
      .query({
        query: gql`
          query {
            getExternalUserByExternalCode(externalCode: "${externalCode}") {
              id
              externalUserCode
              dtStart
              dtEnd
              organizerName
              globalCalendarEventId
              status
              accountId
              organizerId
              originalParticipants: calendarParticipants{
                id
                userId
                accountId
                name
                externalUserId
                type
              }
              calendar{
                externalUser{
                  id
                  name
                  email
                  mobile
                }
              }
            }
          }
        `,
      })
      .then((result) => result?.data?.getExternalUserByExternalCode);
  }

  async resendEmailToExternalUser(externalUserId) {
    return this.client
      .mutate({
        mutation: gql`
          mutation {
            resendEmailToExternalUser(
              externalUserId: "${externalUserId}"
            )
          }
        `,
      })
      .then((result) => result?.data?.resendEmailToExternalUser);
  }
}
const calendarGraphqlInstance = new CalendarGraphql();
export default calendarGraphqlInstance;
