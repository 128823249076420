export const handleFreeTimeEventsIntersections = (freeTimes) => {
  const freeTimeEvents = [...freeTimes];
  const overlapHandledEvents = [];
  // handles overlaps between free times
  for (let i = 0; i < freeTimeEvents.length; i += 1) {
    const freeTime = freeTimeEvents[i];
    if (i !== 0 && freeTime.dtStart < freeTimeEvents[i - 1].dtEnd) {
      freeTime.dtStart = freeTimeEvents[i - 1].dtEnd;
      if (freeTime.dtEnd < freeTime.dtStart) {
        freeTime.dtEnd = freeTime.dtStart;
      }
    }

    if (freeTime.dtStart < freeTime.dtEnd) {
      overlapHandledEvents.push(freeTime);
    }
  }

  let i = 0;
  let intersectionHandledEvents = [...overlapHandledEvents];
  // handles when free times come one after one
  while (i < intersectionHandledEvents.length) {
    if (intersectionHandledEvents[i].dtEnd !== intersectionHandledEvents[i + 1]?.dtStart) {
      i += 1;
      // eslint-disable-next-line no-continue
      continue;
    }

    intersectionHandledEvents = [
      {
        ...intersectionHandledEvents[i],
        dtEnd: intersectionHandledEvents[i + 1].dtEnd,
      },
      // eslint-disable-next-line no-loop-func
      ...intersectionHandledEvents.filter((__value, index) => index !== i && index !== i + 1),
    ]
    // eslint-disable-next-line no-nested-ternary
      .sort((a, b) => ((a.dtStart > b.dtStart)
        ? 1
        : (b.dtStart > a.dtStart)
          ? -1
          : 0
      ));
  }

  return intersectionHandledEvents;
};
