import clsx from 'clsx';

function VerticalDotsIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-1.5 h-6', className)}
      fill="none"
      viewBox="0 0 6 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={3.109} cy={2.598} r={2.598} fill="#9F9F9F" />
      <circle cx={3.109} cy={12} r={2.598} fill="#9F9F9F" />
      <circle cx={3.109} cy={21.402} r={2.598} fill="#9F9F9F" />
    </svg>
  );
}

export default VerticalDotsIcon;
