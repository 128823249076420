import * as React from 'react';
import clsx from 'clsx';

function PictureAddIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-10 h-10', className)}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.9993 16.6667C16.8403 16.6667 18.3327 15.1743 18.3327 13.3333C18.3327 11.4924 16.8403 10 14.9993 10C13.1584 10 11.666 11.4924 11.666 13.3333C11.666 15.1743 13.1584 16.6667 14.9993 16.6667Z"
        stroke="#959595"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6673 3.33398H15.0007C6.66732 3.33398 3.33398 6.66732 3.33398 15.0007V25.0007C3.33398 33.334 6.66732 36.6673 15.0007 36.6673H25.0007C33.334 36.6673 36.6673 33.334 36.6673 25.0007V16.6673"
        stroke="#959595"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.25 8.33398H35.4167"
        stroke="#959595"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M30.834 12.9167V3.75"
        stroke="#959595"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M4.44922 31.5836L12.6659 26.0669C13.9826 25.1836 15.8826 25.2836 17.0659 26.3003L17.6159 26.7836C18.9159 27.9003 21.0159 27.9003 22.3159 26.7836L29.2492 20.8336C30.5492 19.7169 32.6492 19.7169 33.9492 20.8336L36.6659 23.1669"
        stroke="#959595"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PictureAddIcon;
