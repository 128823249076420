import {
    gql,
  } from '@apollo/client';
  import GraphQlService from './graphql';
  
  class DistributedUserGraphql extends GraphQlService {
    async getAllAuthDistributedUserByUserId() {
      return this.client
        .query({
          query: gql`
              query {
                getAllAuthDistributedUserByUserId{
                    id
                    status
                    distributedAccountId
                    distributedUserId
                    distributedUser{
                        accounts{
                            name
                            id
                            dtActivation
                            dtDeactivation
                        }
                        chatChatParticipants{
                            accountId
                            name
                            sendMessageCount
                            readMessageCount	
                            chatRoom{
                                messageCount
                                organizerName
                            }
                        }
                        chatChatParticipantGlobals{
                          name
                          sendMessageCount
                          readMessageCount
                          globalChatRoom{
                            messageCount
                            organizerName
                          }
                        }
                    }
                }
            }
              `,
          fetchPolicy: 'network-only'
        })
        .then((result) => result?.data?.getAllAuthDistributedUserByUserId);
    }
}
const distributedUserInstance = new DistributedUserGraphql();
export default distributedUserInstance;
