import { gql } from "@apollo/client";
import GraphQlService from "./graphql";

class profileGraphql extends GraphQlService {
  async getAllAccountPerson(data) {
    return this.client.query({
      query: gql`
        query {
          getAllAccountPerson(
            userId: ${data.userId ? `"${data.userId}"` : null}
            populateAttributes: ${data.populateAttributes ? data.populateAttributes : false}
            type: ${data.type ? `"${data.type}"` : null}
          ) {
            id
            about
            accountId
            allowEmailNotification
            birthday
            createdAt
            createdBy
            customRolesId
            email
            gender
            organizationId
            profilePictureName
            profileVideoName
            type
            updatedAt
            updatedBy
            userId
            isAnonymous
            isAnywaySendMessage
            isOnline
            isSecretIdentity
            isUnavailable
            masterRolesId
            name
            nameSW
            nameEng
            organizationName
            unavailableMessage
            version
            accountPersonAttributes {
              id
              accountId
              createdAt
              createdBy
              name
              personId
              updatedAt
              updatedBy
              value
              version
            }
          }
        }
      `,
    }).then((result) => result?.data?.getAllAccountPerson);
  }
}

const profileGraphqlInstance = new profileGraphql();
export default profileGraphqlInstance;
