import clsx from "clsx";
import {
  BWOther,
  ColorOther,
} from "@assets/illustrations";
import { USER_ROLES } from "@utils/consts";
import { useContext, useState } from "react";
import { UserContext } from "@contexts/User";
import Loader from "@components/Loader";

const ProfilePictureItem = ({
  profile,
  profilePictureUrl,
  profilePictureStyle,
  alt,
  isGroupChat,
}) => {
  const [imgError, setImgError] = useState(false);
  const [isImgLoading, setIsImgLoading] = useState(true);

  if (profile?.isAnonymous) {
    return <BWOther />;
  }

  const handleProfileIllustration = () => {
    return <ColorOther />;
  };

  if (!profilePictureUrl) {
    return handleProfileIllustration();
  }

  const result = !imgError ? (
    <>
      <img
        className={clsx(
          "rounded-full disabled object-cover",
          profilePictureStyle.className,
          isImgLoading ? "opacity-0" : ""
        )}
        src={profilePictureUrl}
        onError={() => setImgError(true)}
        onLoad={() => setIsImgLoading(false)}
        alt={alt}
      />
      {isImgLoading && (
        <div className="absolute flex w-full h-full top-0 left-0 items-center justify-center">
          <Loader height={25} width={25} />
        </div>
      )}
    </>
  ) : (
    handleProfileIllustration()
  );

  return result;
};

const ProfilePicture = ({
  profile,
  profilePictureUrl,
  profilePictureStyle = {
    className: "w-16 h-16",
    height: 16,
  },
  statusPictureStyle = {
    size: 4,
    className: "w-4 h-4",
  },
  isShowOnline = false,
  alt,
  isGroupChat,
}) => {
  const { htmlFontSize } = useContext(UserContext);
  // eslint-disable-next-line no-nested-ternary
  const onlineStatusSizeBottom = isShowOnline
    ? profilePictureStyle.height < 16
      ? profilePictureStyle.height / statusPictureStyle.size - 1
      : profilePictureStyle.height / statusPictureStyle.size + 1
    : 0;

  return (
    <div className="relative">
      <ProfilePictureItem
        profile={profile}
        profilePictureUrl={profilePictureUrl}
        profilePictureStyle={profilePictureStyle}
        alt={alt}
        isGroupChat={isGroupChat || false}
      />
      {isShowOnline && profile?.type === USER_ROLES.COUNSELLOR_ROLE && (
        <div
          style={{ bottom: `${onlineStatusSizeBottom / htmlFontSize}rem` }}
          className={clsx(
            "absolute rounded-full border-2 border-white right-0",
            statusPictureStyle.className,
            profile.isOnline ? "bg-jungle-green" : "bg-stone"
          )}
        />
      )}
    </div>
  );
};

export default ProfilePicture;
