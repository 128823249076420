import { createContext, useCallback, useEffect, useState } from "react";

import AuthService from "@services/api/auth";
import ProfileService from "@services/api/profile";
import CalendarService from "@services/api/calendar";
import OrganizationService from "@services/api/organization";
import LocalStorageService from "@services/localStorage";
import RolesGraphqlInstance from '@services/api/roles.graphql';
import { ability, convertAbility } from 'ability/ability';

import {
  AFTER_SURVEY_STEPS,
  SURVEY_STATUSES,
  USER_ROLES,
  MEDIA_FONT_SIZE,
} from "@utils/consts";
import calendarGraphqlInstance from "@services/api/calendar.graphql";

export const UserContext = createContext({
  user: null,
  profile: null,
  organization: null,
  surveyStatus: null,
  userCalendar: null,
  htmlFontSize: null,
  isMainLoading: true,
  login: async () => {},
  logout: async () => {},
  register: async () => {},
  registerConsumer: async () => {},
  forgotPassword: async () => {},
  resetPassword: async () => {},
  checkIsSSO: async () => {},
  setHtmlFontSize: () => {},
  distributedLogin: async () => {},
  switchAccountTokens: async () => {},
  setSurveyStatus: async () => {},
  setShowBroadcastModal: () => {},
});

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [surveyStatus, setSurveyStatus] = useState(null);
  const [userCalendar, setUserCalendar] = useState(null);
  const [afterSurveyWrapStep, setAfterSurveyWrapStep] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [htmlFontSize, setHtmlFontSize] = useState(MEDIA_FONT_SIZE.BASE);
  const [showBroadcastModal, setShowBroadcastModal] = useState(false);
  const [currentUserRoots, setCurrentUserRoots] = useState(null);

  const [isMainLoading, setIsMainLoading] = useState(true);

  const setUserContextData = useCallback(
    async (userData, isRegistration = null, showSurvey = true) => {
      const profileResponse = await ProfileService.getProfileByUserId(
        userData.accountId,
        userData.id
      );

      if (
        profileResponse?.type !== USER_ROLES.STUDENT_ROLE &&
        profileResponse?.type !== USER_ROLES.COUNSELLOR_ROLE &&
        profileResponse?.type !== USER_ROLES.PRINCIPAL_ROLE &&
        profileResponse?.type !== USER_ROLES.ALLBRY_COUNSELLOR_ROLE
      ) {
        LocalStorageService.removeAccessToken();
        LocalStorageService.removeRefreshToken();
        throw new Error();
      }

      if (isRegistration) {
        switch (profileResponse?.type) {
          case USER_ROLES.STUDENT_ROLE:
            if (showSurvey) {
              LocalStorageService.setSurveyStatus(
                SURVEY_STATUSES.STUDENT_SURVEY
              );
              setSurveyStatus(SURVEY_STATUSES.STUDENT_SURVEY);
            } else {
              setAfterSurveyWrapStep(AFTER_SURVEY_STEPS.FIRST_STEP);
            }
            break;
          case USER_ROLES.COUNSELLOR_ROLE:
            setAfterSurveyWrapStep(AFTER_SURVEY_STEPS.FIRST_STEP);
            break;
          default:
            break;
        }
      }else if(isRegistration === false){   
        if(profileResponse?.gender === '' || profileResponse?.gender === null){
          LocalStorageService.setSurveyStatus(
            SURVEY_STATUSES.STUDENT_GENDER
          );
          setSurveyStatus(SURVEY_STATUSES.STUDENT_GENDER);
        }
      }

      const userCalendarResponse = await CalendarService.getCalendarByUserId(
        userData.accountId,
        userData.id
      );

      const organizationResponse = await OrganizationService.getOrganizationById(
        userData.accountId,
        profileResponse.organizationId
      );

      // Set roles and permissions
      try{
        const data = await RolesGraphqlInstance.getRoleFeaturesByCurrentUser();
        if(data){
          const rules = convertAbility(data || []);
          ability.update(rules);
        }
      }catch (err) {}

      setUser(userData);
      setUserCalendar(userCalendarResponse);
      setOrganization(organizationResponse);
      setProfile(profileResponse);
    },
    []
  );

  const setUserInitData = useCallback(async () => {
    setIsMainLoading(true);

    const accessToken = LocalStorageService.getAccessToken();
    const refreshToken = LocalStorageService.getRefreshToken();

    if (!accessToken && !refreshToken) {
      setIsMainLoading(false);
      return;
    }

    const localSurveyStatus = LocalStorageService.getSurveyStatus();

    if (localSurveyStatus && accessToken) {
      setSurveyStatus(localSurveyStatus);
    }

    try {
      let userResponse;

      try {
        userResponse = await AuthService.getCurrentUser();
      } catch (err) {
        // Try to refresh token if current one is invalid
        const result = await AuthService.refreshToken();

        if (result) {
          LocalStorageService.setAccessToken(result.accessToken);
          LocalStorageService.setRefreshToken(result.refreshToken);

          // Current user after token refresh
          userResponse = await AuthService.getCurrentUser();
        }
      }

      await setUserContextData(userResponse);
    } catch (err) {
      LocalStorageService.removeAccessToken();
      LocalStorageService.removeRefreshToken();
      LocalStorageService.removeAppCache();
    } finally {
      setIsMainLoading(false);
    }
  }, [setUserContextData]);

  useEffect(() => {
    setUserInitData();

    // This is needed to track local storage changes from other browser tab
    // (changes from the tab listener was created in are not triggering event)
    const storageHandler = (e) => {
      // local storage key
      if (e.key === "accessToken") {
        setUserInitData();
      }
    };

    window.addEventListener("storage", storageHandler);

    return () => {
      window.removeEventListener("storage", storageHandler);
    };
  }, [setUserInitData]);

  const login = useCallback(async (email, password) => {
    const result = await AuthService.login(email, password);
    if (!result.accessToken) {
      if (result?.userId) {
        return result;
      }

      if (result.isMissingPassword) {
        return false;
      }
    }

    if (result.redirectUrl || !result.accessToken) {
      return true;
    }

    if (result.redirectUrl || !result.accessToken) {
      return true;
    }

    LocalStorageService.setAccessToken(result.accessToken);
    LocalStorageService.setRefreshToken(result.refreshToken);

    const userResponse = await AuthService.getCurrentUser();
    await setUserContextData(userResponse, false);

    return true;
  }, []);

  const setTokens = useCallback(async (tokens) => {
    LocalStorageService.setAccessToken(tokens.accessToken);
    LocalStorageService.setRefreshToken(tokens.refreshToken);

    const userResponse = await AuthService.getCurrentUser();
    await setUserContextData(userResponse, false);
  }, []);

  const register = useCallback(async (email, password) => {
    const result = await AuthService.emailRegistration(email, password);

    LocalStorageService.setAccessToken(result.accessToken);
    LocalStorageService.setRefreshToken(result.refreshToken);

    const userResponse = await AuthService.getCurrentUser();
    await setUserContextData(userResponse, true);
  }, []);

  const registerConsumer = useCallback(async (accessToken, refreshToken) => {
    LocalStorageService.setAccessToken(accessToken);
    LocalStorageService.setRefreshToken(refreshToken);

    const userResponse = await AuthService.getCurrentUser();
    await setUserContextData(userResponse, true, false);
  }, []);

  const checkIsSSO = useCallback(async (email, isRegistration = false) => {
    const isSSO = await AuthService.isSSO(email, isRegistration);
    return isSSO;
  }, []);

  const forgotPassword = useCallback(async (email) => {
    const result = await AuthService.forgotPassword(email);
    return result;
  }, []);

  const resetPassword = useCallback(async (newPassword, token) => {
    const result = await AuthService.resetPassword(newPassword, token);

    LocalStorageService.setAccessToken(result.accessToken);
    LocalStorageService.setRefreshToken(result.refreshToken);

    const userResponse = await AuthService.getCurrentUser();
    await setUserContextData(userResponse);
  }, []);

  const logout = useCallback(async () => {
    await AuthService.logout();

    LocalStorageService.removeAccessToken();
    LocalStorageService.removeRefreshToken();
    LocalStorageService.removeAppCache();

    // Order is very important
    setProfile(null);
    setUser(null);
    setUserCalendar(null);
    setOrganization(null);
  }, []);

  const completeSurvey = () => {
    setSurveyStatus(null);
    LocalStorageService.removeSurveyStatus();
    setAfterSurveyWrapStep(AFTER_SURVEY_STEPS.FIRST_STEP);
  };

  const updateCurrentProfile = useCallback(
    async (profileData) => {
      const newProfile = {
        ...profile,
        ...profileData,
      };
      const updatedProfile = await ProfileService.updateProfileById(
        profile.accountId,
        profile.id,
        newProfile
      );
      setProfile(updatedProfile);
    },
    [profile]
  );

  const updateCalendarSyncStatus = useCallback(
    async (status) => {
      const updatedCalender = await calendarGraphqlInstance.updateCalendarSyncStatus(status);
      setUserCalendar(updatedCalender);
    },
    [userCalendar]
  );

  const updateCalendarSyncFreeTimeStatus = useCallback(
    async (status) => {
      const updatedCalender = await calendarGraphqlInstance.updateCalendarSyncFreeTimeStatus(status);
      setUserCalendar(updatedCalender);
    },
    [userCalendar]
  );

  const updateOrganization = useCallback(async (form) => {
    const newOrganization = await OrganizationService.updateOrganization(
      organization.accountId,
      organization.id,
      {
        ...form,
        id: organization.id,
        name: organization.name,
        accountId: organization.accountId,
      }
    );
    setOrganization(newOrganization);
  });

  const switchAccountTokens = useCallback(async (accountId) => {
    const result = await AuthService.switchAccount(accountId);

    LocalStorageService.setAccessToken(result.accessToken);
    LocalStorageService.setRefreshToken(result.refreshToken);
  });

  const distributedLogin = useCallback(async (accountId) => {
    await switchAccountTokens(accountId);

    try {
      const userResponse = await AuthService.getCurrentUser();
      await setUserContextData(userResponse);
    } catch (e) {
      LocalStorageService.removeAccessToken();
      LocalStorageService.removeRefreshToken();
      LocalStorageService.removeAppCache();

      setProfile(null);
      setUser(null);
      setUserCalendar(null);
      setOrganization(null);
    }
  }, []);

  const setUserRootsCall = useCallback((roots) => {
    setCurrentUserRoots(roots);
  });

  const value = {
    user,
    isMainLoading,
    profile,
    userCalendar,
    organization,
    surveyStatus,
    completeSurvey,
    login,
    logout,
    register,
    registerConsumer,
    forgotPassword,
    resetPassword,
    updateCurrentProfile,
    afterSurveyWrapStep,
    setAfterSurveyWrapStep,
    setTokens,
    checkIsSSO,
    updateOrganization,
    htmlFontSize,
    setHtmlFontSize,
    distributedLogin,
    switchAccountTokens,
    setSurveyStatus,
    updateCalendarSyncStatus,
    setShowBroadcastModal,
    showBroadcastModal,
    setUserRootsCall,
    currentUserRoots,
    updateCalendarSyncFreeTimeStatus,
  };

  return (
    <UserContext.Provider value={{ ...value }}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
