import * as React from 'react';
import clsx from 'clsx';

function SearchIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-6 h-6', className)}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.741 21.49l-5.537-5.537a9.68 9.68 0 002.258-6.222C19.462 4.365 15.097 0 9.73 0S0 4.365 0 9.73c0 5.366 4.365 9.732 9.731 9.732 2.365 0 4.534-.85 6.222-2.258l5.537 5.537a.882.882 0 001.25 0 .884.884 0 00.001-1.252zM1.769 9.73c0-4.39 3.572-7.96 7.962-7.96s7.962 3.57 7.962 7.96c0 4.39-3.572 7.962-7.962 7.962s-7.962-3.571-7.962-7.961z"
        fill="#ADADAD"
      />
    </svg>
  );
}

export default SearchIcon;
