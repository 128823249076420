function SvgComponent() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 1737 719" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_5227_22285" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="1193" y="0" width="544" height="719">
        <rect x="1193" width="544" height="719" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_5227_22285)">
        <circle r="3.5008" transform="matrix(-0.0143403 -0.999897 -0.999897 0.0143403 1396.45 391.188)" fill="white" fillOpacity="0.26" />
        <circle r="3.5008" transform="matrix(-0.0143403 -0.999897 -0.999897 0.0143403 1607.55 440.55)" fill="white" fillOpacity="0.26" />
        <circle r="3.5008" transform="matrix(-0.0143403 -0.999897 -0.999897 0.0143403 1444.55 617.55)" fill="white" fillOpacity="0.26" />
        <circle r="3.5008" transform="matrix(-0.989238 -0.146312 -0.146312 0.989238 1331.29 246.523)" fill="white" fillOpacity="0.26" />
        <circle r="3.5008" transform="matrix(-0.989238 -0.146312 -0.146312 0.989238 1596.02 120.86)" fill="white" fillOpacity="0.26" />
        <circle r="3.5008" transform="matrix(0.999396 -0.034757 -0.034757 -0.999396 1538.62 245.621)" fill="white" fillOpacity="0.26" />
        <circle r="3.5008" transform="matrix(0.999396 -0.034757 -0.034757 -0.999396 1299.1 117.104)" fill="white" fillOpacity="0.26" />
        <circle r="9" transform="matrix(0.656709 0.754144 0.754144 -0.656709 1645.7 312.328)" fill="white" fillOpacity="0.26" />
        <circle r="9" transform="matrix(0.84976 -0.527169 -0.527169 -0.84976 1372.5 69.9067)" fill="white" fillOpacity="0.26" />
        <circle r="9" transform="matrix(-0.740576 0.671973 0.671973 0.740576 1316.68 595.713)" fill="white" fillOpacity="0.26" />
        <circle r="5.7501" transform="matrix(-0.711285 -0.702903 -0.702903 0.711285 1486.13 461.132)" fill="white" fillOpacity="0.26" />
        <circle r="5.7501" transform="matrix(-0.807942 0.589262 0.589262 0.807942 1502.03 205.034)" fill="white" fillOpacity="0.26" />
        <circle r="5.7501" transform="matrix(0.688231 -0.725492 -0.725492 -0.688231 1435.13 307.128)" fill="white" fillOpacity="0.26" />
        <path d="M1503.01 98.6635L1499 99.3454" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1500.66 96.9997L1501.34 101.008" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1390.29 184.267L1390.31 188.332" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1388.27 186.315L1392.33 186.285" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1317.29 92.2667L1317.31 96.3323" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1315.27 94.3149L1319.33 94.2851" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1526.91 396.441L1526.14 392.447" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1528.52 394.062L1524.53 394.825" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1280.43 663.041L1279.66 659.048" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1282.04 660.662L1278.05 661.426" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1591.53 550.865L1590.77 546.872" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1593.15 548.486L1589.16 549.25" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1421.05 520.232L1424.99 519.253" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1423.51 521.716L1422.53 517.77" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1644.34 200.195L1644 196.143" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1646.19 198.002L1642.14 198.336" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1266.66 328.223L1267.72 332.148" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1265.22 330.716L1269.15 329.656" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1635.27 645.36L1642.67 636.583" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1643.36 644.67L1634.59 637.274" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1658.5 64.1177L1651.02 55.4093" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1659.12 56.0238L1650.41 63.5003" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1344.52 489.377L1353.45 496.592" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1345.38 497.449L1352.59 488.522" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1496.52 285.377L1505.45 292.592" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1497.38 293.449L1504.59 284.522" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
      </g>
      <mask id="mask1_5227_22285" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="544" height="719">
        <rect width="544" height="719" transform="matrix(-1 0 0 1 544 0)" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask1_5227_22285)">
        <circle cx="340.555" cy="391.188" r="3.5008" transform="rotate(-89.1783 340.555 391.188)" fill="white" fillOpacity="0.26" />
        <circle cx="129.449" cy="440.55" r="3.5008" transform="rotate(-89.1783 129.449 440.55)" fill="white" fillOpacity="0.26" />
        <circle cx="292.449" cy="617.55" r="3.5008" transform="rotate(-89.1783 292.449 617.55)" fill="white" fillOpacity="0.26" />
        <circle cx="405.711" cy="246.523" r="3.5008" transform="rotate(-8.41329 405.711 246.523)" fill="white" fillOpacity="0.26" />
        <circle cx="140.975" cy="120.86" r="3.5008" transform="rotate(-8.41329 140.975 120.86)" fill="white" fillOpacity="0.26" />
        <circle cx="198.38" cy="245.621" r="3.5008" transform="rotate(-178.008 198.38 245.621)" fill="white" fillOpacity="0.26" />
        <circle cx="437.905" cy="117.104" r="3.5008" transform="rotate(-178.008 437.905 117.104)" fill="white" fillOpacity="0.26" />
        <circle cx="91.3023" cy="312.328" r="9" transform="rotate(131.049 91.3023 312.328)" fill="white" fillOpacity="0.26" />
        <circle cx="364.496" cy="69.9067" r="9" transform="rotate(-148.186 364.496 69.9067)" fill="white" fillOpacity="0.26" />
        <circle cx="420.322" cy="595.713" r="9" transform="rotate(42.2195 420.322 595.713)" fill="white" fillOpacity="0.26" />
        <circle cx="250.868" cy="461.132" r="5.7501" transform="rotate(-44.6604 250.868 461.132)" fill="white" fillOpacity="0.26" />
        <circle cx="234.966" cy="205.034" r="5.7501" transform="rotate(36.1046 234.966 205.034)" fill="white" fillOpacity="0.26" />
        <circle cx="301.871" cy="307.128" r="5.7501" transform="rotate(-133.49 301.871 307.128)" fill="white" fillOpacity="0.26" />
        <path d="M233.992 98.6635L238 99.3454" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M236.338 96.9997L235.656 101.008" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M346.714 184.267L346.685 188.332" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M348.733 186.315L344.667 186.285" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M419.714 92.2667L419.685 96.3323" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M421.733 94.3149L417.667 94.2851" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M210.093 396.441L210.856 392.447" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M208.478 394.062L212.471 394.825" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M456.574 663.041L457.338 659.048" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M454.959 660.662L458.952 661.426" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M145.466 550.865L146.229 546.872" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M143.851 548.486L147.844 549.25" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M315.954 520.232L312.008 519.253" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M313.49 521.716L314.47 517.77" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M92.6636 200.195L92.9974 196.143" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M90.8046 198.002L94.8566 198.336" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M470.345 328.223L469.285 332.148" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M471.777 330.716L467.852 329.656" stroke="white" strokeWidth="1.81627" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M101.726 645.36L94.3299 636.583" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M93.6367 644.67L102.414 637.274" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M78.5 64.1177L85.9765 55.4093" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M77.8827 56.0238L86.5911 63.5003" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M392.478 489.377L383.551 496.592" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M391.622 497.449L384.407 488.522" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M240.478 285.377L231.551 292.592" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M239.622 293.449L232.407 284.522" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
      </g>
    </svg>
  );
}

export default SvgComponent;
