import {
  useState, useMemo, useCallback,
} from 'react';
import {useDropzone} from 'react-dropzone';
import {Attachment} from '@components/Static';
import {useTranslation} from 'react-i18next';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '15rem',
  margin: '0px 0.625rem 1.25rem',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#FCB96B',
};

const acceptStyle = {
  borderColor: '#FCB96B',
};

const rejectStyle = {
  borderColor: '#EE6A5F',
};

const DragAndDropZone = ({setItems, acceptedFileTypes}) => {
  const [file, setFile] = useState(null);
  const {t} = useTranslation();

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
    setItems(acceptedFiles[0]);
  }, []);

  const onRemove = () => {
    setFile(null);
    setItems(null);
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({onDrop, noClick: !!file, accept: acceptedFileTypes});

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
  ]);

  return (
    <div className="container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} multiple={false} />
        {!file ? (<p className="text-center">{t('common.dragNDrop')}</p>) : (
          <Attachment
            fileName={file.name.split('.')[0]}
            fileType={file.name.split('.').pop()}
            size={file.size}
            onClick={onRemove}
            className="mt-2 mr-1 p-2 hover:bg-light-red hover:bg-opacity-5"
            maxFileNameLength={40}
          />
        )}
      </div>
    </div>
  );
};

export default DragAndDropZone;
