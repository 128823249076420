import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

function ArchiveIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-5 h-5', className)}
      fill="none"
      viewBox="0 0 22 23"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.5 8.218c0-.455.37-.825.825-.825h8.8a.825.825 0 010 1.65h-8.8a.825.825 0 01-.825-.825zm1.925-2.475h6.6a.825.825 0 000-1.65h-6.6a.825.825 0 000 1.65zm14.025 6.31v2.765a5.231 5.231 0 01-5.225 5.225h-11A5.231 5.231 0 010 14.818v-2.764c0-.445.072-.884.211-1.305L2.34 4.366A5.218 5.218 0 017.296.793h6.858a5.218 5.218 0 014.956 3.573l2.129 6.383c.14.42.211.86.211 1.305zm-19.481-1.36h5.456c.455 0 .825.37.825.825a2.477 2.477 0 002.475 2.475 2.477 2.477 0 002.475-2.475c0-.455.37-.825.825-.825h5.456l-1.935-5.806a3.57 3.57 0 00-3.391-2.444H7.297a3.57 3.57 0 00-3.39 2.444l-1.938 5.806zM19.8 14.818v-2.475h-5.033a4.134 4.134 0 01-4.042 3.3 4.134 4.134 0 01-4.042-3.3H1.65v2.475a3.58 3.58 0 003.575 3.575h11a3.58 3.58 0 003.575-3.575z"
      />
    </svg>
  );
}

ArchiveIcon.propTypes = {
  className: PropTypes.string,
};

ArchiveIcon.defaultProps = {
  className: 'fill-current text-dawn-gray',
};

export default ArchiveIcon;
