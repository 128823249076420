import ProfileVideo from "@components/ProfileVideo/ProfileVideo";
import CustomModal from "../CustomModal/CustomModal";
import { useProfileVideoUrl } from "@hooks/profile";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Button } from "@components/Form";
import { useTranslation } from "react-i18next";
import styles from "./VideoPlayerModal.module.scss";

const VideoPlayerModal = ({
  openModal,
  setOpenModal,
  className,
  profile,
  controlsAllowed,
  isViewMode,
}) => {
  const { t } = useTranslation();
  const profileVideoUrl = useProfileVideoUrl(
    profile.profileVideoName,
    profile.accountId
  );

  return (
    <>
      <CustomModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        className={clsx(
          "bg-milk rounded-xl pt-10 pb-2 px-10",
          styles.videoModal,
          className
        )}
      >
        <div className="flex flex-col items-center">
          <div className="h-14 px-12 flex flex-row">
            <span className="font-bold-poppins text-3xl text-dawn-gray tracking-tight m-0">
              {t("common.helloVideoTitle")}
            </span>
          </div>
          <div className="mt-3">
            {profileVideoUrl ? (
              <>
                <ProfileVideo
                  profileVideoUrl={profileVideoUrl}
                  profileVideoStyle={{
                    className: "w-full",
                  }}
                  style={{ maxHeight: "500px" }}
                  controlsAllowed={controlsAllowed}
                  isViewMode={isViewMode}
                />
              </>
            ) : (
              <div
                style={{ height: "150px" }}
                className="flex flex-col items-center justify-center flex-1"
              >
                <h3 className="text-silver text-xl">
                  {t("common.noFound", { name: "video" })}
                </h3>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-wrap justify-end my-2 py-2">
          <Button
            variant="edit"
            className="rounded-full items-center px-12 py-4 mx-5"
            onClick={setOpenModal}
          >
            <span className="text-white font-medium-poppins tracking-tight flex">
              {t("common.close")}
            </span>
          </Button>
        </div>
      </CustomModal>
    </>
  );
};

VideoPlayerModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  className: PropTypes.string,
  profile: PropTypes.object.isRequired,
  controlsAllowed: PropTypes.bool,
  isViewMode: PropTypes.bool,
};

VideoPlayerModal.defaultProps = {
  className: "",
  profileVideoUrl: "",
  controlsAllowed: true,
  isViewMode: false,
};

export default VideoPlayerModal;
