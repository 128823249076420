import * as React from 'react';
import clsx from 'clsx';

function LogoutIcon({className, stroke = '#EE6A5F', ...props}) {
  return (
    <svg
      className={clsx('w-6 h-6', className)}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.889 7.62c.31-3.6 2.16-5.07 6.21-5.07h.13c4.47 0 6.26 1.79 6.26 6.26v6.52c0 4.47-1.79 6.26-6.26 6.26h-.13c-4.02 0-5.87-1.45-6.2-4.99M15.987 12.06H4.607M6.838 8.71l-3.35 3.35 3.35 3.35"
        stroke={stroke}
        strokeWidth={1.8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LogoutIcon;
