import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SURVEY_ROOT } from '@router/consts';

export const RedirectSurvey = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(SURVEY_ROOT);
  }, [navigate]);

  return null;
};