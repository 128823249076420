import PropTypes from 'prop-types';
import clsx from 'clsx';

import ButtonGroupItem from './ButtonGroupItem';

const ButtonGroup = ({
  options, selectedOptions, onChange, variant, variantSelected, className,
}) => (
  <div className={clsx('text-base text-dawn-gray', className)}>
    {options.map((option, index) => (
      <ButtonGroupItem
        key={option.value}
        label={option.label}
        isChecked={selectedOptions?.some((v) => v.value === option.value)}
        onClick={() => onChange(option)}
        variant={variant}
        variantSelected={variantSelected}
        animationDelay={index * (variant === 'report' ? 0.025 : 0.2)}
      />
    ))}
  </div>
);

ButtonGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({label: PropTypes.string, value: PropTypes.string})),
  selectedOptions:
    PropTypes.arrayOf(PropTypes.shape({label: PropTypes.string, value: PropTypes.string})),
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'report']),
  variantSelected: PropTypes.oneOf(['selected', 'reportSelected']),
};

ButtonGroup.defaultProps = {
  onChange: () => {},
  options: [],
  selectedOptions: [],
  variant: 'default',
  variantSelected: 'selected',
};

export default ButtonGroup;
