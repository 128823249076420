import * as React from 'react';
import clsx from 'clsx';

function VideoIcon({className, size='w-7 h-5', color, ...props}) {
  return (
    <svg
      className={clsx(size, className)}
      fill="none"
      viewBox="0 0 28 19"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.524 3.196a2.847 2.847 0 00-2.873.132l-4.322 2.815C19.04 2.713 16.135 0 12.564 0H6.821C3.06 0 0 3 0 6.685v5.63C0 16 3.06 19 6.82 19h5.744c3.571 0 6.477-2.713 6.765-6.143l4.322 2.816a2.849 2.849 0 002.873.132A2.734 2.734 0 0028 13.387V5.614c0-1.013-.566-1.94-1.476-2.418zm-9.293 9.12c0 2.522-2.094 4.574-4.667 4.574H6.821c-2.574 0-4.667-2.052-4.667-4.574v-5.63c0-2.522 2.093-4.574 4.667-4.574h5.743c2.573 0 4.667 2.052 4.667 4.575v5.63zm8.615 1.071a.618.618 0 01-.34.557.645.645 0 01-.663-.029l-5.458-3.555V8.642l5.457-3.556a.643.643 0 01.664-.03c.102.053.34.215.34.557v7.774z"
        fill={color || '#505050'}
      />
    </svg>
  );
}

export default VideoIcon;
