import React from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {Logo} from '@assets/icons';
import {
  ROOT,
  AUTH_ROOT_LOGIN,
} from '@router/consts';
import {BaseLink} from '@components/Form';

const AuthLayout = ({route}) => {
  const location = useLocation();
  const { pathname } = location;

  const viewLogo = (pathname !== AUTH_ROOT_LOGIN)
    || location.search !== '';

  return (
    <div className="bg-main min-h-screen h-full flex relative">
      {(viewLogo && !window.ReactNativeWebView) && <BaseLink to={ROOT} className="absolute top-10 left-10 cursor-pointer"><Logo /></BaseLink>}
      <Outlet />
    </div>
  );
};
export default AuthLayout;
