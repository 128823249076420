import * as React from 'react';
import clsx from 'clsx';

const DotsIcon = ({className, ...props}) => (
  <svg
    className={clsx('w-1 h-5', className)}
    fill="none"
    viewBox="0 0 4 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 0C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm0 14c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm0-7C.9 7 0 7.9 0 9s.9 2 2 2 2-.9 2-2-.9-2-2-2Z"
      fill="#C4C4C4"
    />
  </svg>
);

export default DotsIcon;
