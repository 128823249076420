import * as React from 'react';
import clsx from 'clsx';

function EyeIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-5 h-3.5', className)}
      fill="none"
      viewBox="0 0 21 15"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#585550">
        <path fill="#585550" d="M12.458 6.708a2.875 2.875 0 11-5.75 0 2.875 2.875 0 015.75 0z" />
        <path fill="#585550" d="M19.066 6.28C16.997 2.143 13.323 0 9.582 0 5.843 0 2.17 2.143.101 6.28a.958.958 0 000 .857c2.068 4.137 5.742 6.28 9.482 6.28 3.74 0 7.414-2.143 9.482-6.28a.958.958 0 000-.857zM9.582 11.5c-2.845 0-5.752-1.553-7.542-4.792C3.83 3.47 6.738 1.917 9.583 1.917c2.845 0 5.752 1.553 7.543 4.791-1.79 3.239-4.698 4.792-7.543 4.792z" />
      </g>
    </svg>
  );
}

export default EyeIcon;
