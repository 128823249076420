import clsx from "clsx";

function CheckboxUnchecked({
  className,
  size = "w-9 h-9",
  color = "#D1C7BB",
  ...props
}) {
  return (
    <svg
      className={clsx(size, className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 34 34"
      {...props}
    >
      <circle cx={17} cy={17} r={17} fill={color} />
      <path d="M23.263 11.633l-9.227 9.842L9.842 17" fill={color} />
    </svg>
  );
}

export default CheckboxUnchecked;
