import { gql } from "@apollo/client";
import GraphQlService from "./graphql";

class BroadcastMessageGraphql extends GraphQlService {
  async getAllBroadcastMessages() {
    return this.client
      .query({
        query: gql`
          query {
            getAllBroadcastMessages {
              id
              startDate
              endDate
              message
              heading
              userType
              status
              isAllAccount
              authAccounts{
                id
                name
              }
            }
          }
        `,
      })
      .then((result) => result?.data?.getAllBroadcastMessages);
  }

  async createBroadcastMessage(broadcastMessage) {
    return this.client
      .mutate({
        mutation: gql`
          mutation {
            createBroadcastMessages(
              createBroadcastMessagesInput: {
                isAllAccount: ${broadcastMessage.isAllAccount}
                accountIds: """${JSON.stringify(broadcastMessage.accountIds)}"""
                startDate: "${broadcastMessage.startDate}"
                endDate: "${broadcastMessage.endDate}"
                message: ""${JSON.stringify(broadcastMessage.message)}""
                heading: "${broadcastMessage.heading}"
                userType: "${broadcastMessage.userType}"
              }
            ) {
              id
            }
          }
        `,
      })
      .then((result) => result?.data?.createBroadcastMessages);
  }

  async updateBroadcastMessage(broadcastMessage) {
    return this.client
      .mutate({
        mutation: gql`
          mutation {
            updateBroadcastMessages(
              updateBroadcastMessagesInput: {
                id: "${broadcastMessage.id}"
                isAllAccount: ${broadcastMessage.isAllAccount}
                accountIds: """${JSON.stringify(broadcastMessage.accountIds)}"""
                startDate: "${broadcastMessage.startDate}"
                endDate: "${broadcastMessage.endDate}"
                message: ""${JSON.stringify(broadcastMessage.message)}""
                heading: "${broadcastMessage.heading}"
                userType: "${broadcastMessage.userType}"
              }
            ) {
              id
            }
          }
        `,
      })
      .then((result) => result?.data?.updateBroadcastMessages);
  }

  async updateStatusBroadcastMessages(data) {
    return this.client
      .mutate({
        mutation: gql`
          mutation {
            updateStatusBroadcastMessages(
              updateStatusBroadcastMessageInput:{
                id: "${data.id}", status: ${data.status}
              }
            ) {
              id
            }
          }
        `,
      })
      .then((result) => result?.data?.updateStatusBroadcastMessages);
  }

  async removeBroadcastMessages(id) {
    return this.client
      .mutate({
        mutation: gql`
          mutation {
            removeBroadcastMessages(id: "${id}")
          }
        `,
      })
      .then((result) => result?.data?.removeBroadcastMessages);
  }

  async getBroadcastMessagesByUser() {
    return this.client
      .query({
        query: gql`
          query {
            getBroadcastMessagesByUser {
              id
              message
              heading
            }
          }
        `,
      })
      .then((result) => result?.data?.getBroadcastMessagesByUser);
  }

  async createBroadcastMessagesUsers(id) {
    return this.client
      .mutate({
        mutation: gql`
          mutation {
            createBroadcastMessagesUsers(
              createBroadcastMessagesUsersInput: {
                messageId: "${id}"
              }
            ), {
              id
            }
          }
        `,
      })
      .then((result) => result?.data?.createBroadcastMessagesUsers);
  }

}

const broadcastMessagesInstance = new BroadcastMessageGraphql();
export default broadcastMessagesInstance;
