import * as React from 'react';
import clsx from 'clsx';

function MicrophoneIcon({className, color, ...props}) {
  return (
    <svg
      className={clsx('w-5 h-6', className)}
      fill="none"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 16.615c3.38 0 6.129-2.622 6.129-5.846V5.846C16.129 2.623 13.379 0 10 0 6.62 0 3.871 2.623 3.871 5.846v4.923c0 3.224 2.75 5.846 6.129 5.846zM5.806 5.846c0-2.205 1.882-4 4.194-4 2.312 0 4.194 1.795 4.194 4v4.923c0 2.206-1.882 4-4.194 4-2.312 0-4.194-1.794-4.194-4V5.846zm5.162 14.415v1.893h4.193c.534 0 .968.413.968.923s-.434.923-.968.923H4.84c-.534 0-.968-.413-.968-.923s.434-.923.968-.923h4.193V20.26C3.97 19.795 0 15.717 0 10.769c0-.51.434-.923.968-.923s.967.414.967.923c0 4.242 3.619 7.693 8.065 7.693 4.447 0 8.064-3.451 8.064-7.693 0-.51.434-.923.968-.923.535 0 .968.414.968.923 0 4.948-3.97 9.025-9.032 9.492z"
        fill={color || '#505050'}
      />
    </svg>
  );
}

export default MicrophoneIcon;
