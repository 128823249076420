import {useEffect, useState} from 'react';

export const useIntervalTrigger = (intervalValue) => {
  const [intervalTrigger, setIntervalTrigger] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIntervalTrigger((prev) => !prev);
    }, intervalValue);

    return () => clearInterval(interval);
  }, [intervalValue]);

  return intervalTrigger;
};
