import config from '@config/config';
import ApiService from './api';

class ReportApi extends ApiService {
  constructor() {
    if (ReportApi.instance) {
      return ReportApi.instance;
    }

    super(config.reportApiUrl);
    ReportApi.instance = this;
  }

  async createReport(accountId, report) {
    const response = await this.http.post(`/accounts/${accountId}/reports`, report);
    return response.data;
  }
  
  async getAllReports(accountId, userId = '', customRolesId = '') {
    customRolesId = customRolesId ? customRolesId : '';
    const response = await this.http.get(`/accounts/${accountId}/reports/?userId=${userId}&customRolesId=${customRolesId}`);
    return response.data;
  }
  async getWorries(accountId, dtStart, dtEnd) {
    const response = await this.http.get(`/accounts/${accountId}/statistics/worryLevel${dtStart ? `?dtStart=${dtStart}&dtEnd=${dtEnd}` : ''}`);
    return response.data;
  }

  async getTopics(accountId, dtStart, dtEnd) {
    const response = await this.http.get(`/accounts/${accountId}/statistics/topics${dtStart ? `?dtStart=${dtStart}&dtEnd=${dtEnd}` : ''}`);
    return response.data;
  }

  async getMeetingType(accountId, dtStart, dtEnd) {
    const response = await this.http.get(`/accounts/${accountId}/statistics/meetingType${dtStart ? `?dtStart=${dtStart}&dtEnd=${dtEnd}` : ''}`);
    return response.data;
  }

  async getMeetingKind(accountId, dtStart, dtEnd) {
    const response = await this.http.get(`/accounts/${accountId}/statistics/meetingKind${dtStart ? `?dtStart=${dtStart}&dtEnd=${dtEnd}` : ''}`);
    return response.data;
  }
  
  async getMeetingDuration(accountId, dtStart, dtEnd) {
    const response = await this.http.get(`/accounts/${accountId}/statistics/meetingDuration${dtStart ? `?dtStart=${dtStart}&dtEnd=${dtEnd}` : ''}`);
    return response.data;
  }

  async getAdditionalSupport(accountId, dtStart, dtEnd) {
    const response = await this.http.get(`/accounts/${accountId}/statistics/additionalSupport${dtStart ? `?dtStart=${dtStart}&dtEnd=${dtEnd}` : ''}`);
    return response.data;
  }
}

const reportApiInstance = new ReportApi();
export default reportApiInstance;
