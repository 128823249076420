import {useEffect, useRef} from 'react';
import clsx from 'clsx';

import {Calendar} from '@assets/icons';
import { useUnansweredEvent } from '@hooks/appointment';
import { AppointmentReportContext } from '@contexts/AppointmentReport';
import { useContext } from 'react';

const AppointmentIcon = ({isActiveTab, className, ...props}) => {
  const isMounted = useRef(false);

  const [unansweredEvent, fetchData] = useUnansweredEvent();
  const {appointmentSocket} = useContext(AppointmentReportContext);

  const needUpdate =  (msg) => {
    fetchData();
  };

  useEffect(() => {
    if (!appointmentSocket || appointmentSocket.disconnected) {
      return;
    }
    appointmentSocket.on('appointment', needUpdate);
    return () => {
      appointmentSocket.off('appointment', needUpdate);
    };
  },[appointmentSocket?.connected]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
  }, []);

  return (
    <div className="relative">
      {unansweredEvent && unansweredEvent.length > 0
        ? (
          <div className={clsx(
            'absolute -right-2/5 -top-35% h-6 w-6 bg-primary rounded-full flex items-center justify-center border-1.5',
            isActiveTab ? 'border-pampas' : 'border-main',
          )}
          >
            <span className="text-white font-light-poppins font-black text-sm leading-5">
              {unansweredEvent.length}
            </span>
          </div>
        )
        : null}
      <Calendar className={className} {...props} />
    </div>
  );
};

export default AppointmentIcon;
