import {
  getDay,
} from 'date-fns';

export const WEEK_DAYS = {
  0: 'sun',
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thu',
  5: 'fri',
  6: 'sat',
};

export const getWorkingHours = (schedule = {}, dateTime = Date.now()) => {
  const weekDay = getDay(dateTime);
  const daySchedule = schedule[WEEK_DAYS[weekDay]];

  if (!daySchedule) {
    return null;
  }

  // have to handle 00:00 as 1 millisecond so functions do not treat 0 as empty value
  const workTimeStart = daySchedule.workTimeStart === 0 ? 1 : daySchedule.workTimeStart;
  const workTimeEnd = daySchedule.workTimeEnd === 0 ? 1 : daySchedule.workTimeEnd;

  if (!workTimeStart || !workTimeEnd) {
    return null;
  }

  return {
    workTimeStart,
    workTimeEnd,
  };
};
