import * as React from 'react';
import clsx from 'clsx';

function FileIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-5 h-6', className)}
      fill="none"
      viewBox="0 0 20 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.338 6.317L13.643.657A2.25 2.25 0 0012.046 0H6.13C2.749 0 0 2.732 0 6.09v12.82C0 22.268 2.75 25 6.129 25h7.742C17.251 25 20 22.268 20 18.91V7.902c0-.598-.235-1.162-.662-1.585zm-5.79-3.035l3.147 3.127h-.889a2.253 2.253 0 01-2.258-2.244v-.883zm.323 19.795H6.129c-2.312 0-4.194-1.87-4.194-4.167V6.09c0-2.298 1.882-4.167 4.194-4.167h5.484v2.244c0 2.297 1.881 4.166 4.194 4.166h2.257V18.91c0 2.298-1.88 4.167-4.193 4.167z"
        fill="#505050"
      />
    </svg>
  );
}

export default FileIcon;
