import clsx from 'clsx';

function WriteMessageIcon({className,color='#FCB96B', ...props}) {
  return (
    <svg
      className={clsx('w-8 h-8', className)}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.75 5H5a2.5 2.5 0 00-2.5 2.5V25A2.5 2.5 0 005 27.5h17.5A2.5 2.5 0 0025 25v-8.75"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.125 3.124a2.652 2.652 0 113.75 3.75L15 18.75 10 20l1.25-5L23.125 3.124z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default WriteMessageIcon;
