export const appointmentMeetingTypeOptions = [
    {label: 'reports.meetingType.emergencyMeeting', value: 'Emergency meeting', color: '#FF7F50'},
    {label: 'reports.meetingType.dropInMeeting', value: 'Drop-in meeting', color: '#CD5C5C'},
    {label: 'reports.meetingType.individualMeeting', value: 'Individual meeting', color: '#F08080'},
    {label: 'reports.meetingType.noShowMeeting', value: 'No-show meeting', color: '#E9967A'},
    {label: 'reports.meetingType.parentMeeting', value: 'Meeting with guardian', color: '#FA8072'},
    {label: 'reports.meetingType.ehtMeeting', value: 'Meeting with representative from EHT', color: '#FFA07A'},
    {label: 'reports.meetingType.groupMeeting', value: 'Group meeting with students', color: '#B8860B'},
    {label: 'reports.meetingType.adultMeeting', value: 'Meeting with other adult (i.e. teacher)', color: '#DAA520'},
    {label: 'reports.meetingType.breakMeeting', value: 'Meeting in hallway or during break', color: '#DAA520'},
    {label: 'reports.meetingType.adultStudentMeeting', value: 'Meeting with student, guardian, principal and / or other school staff', color: '#DAA520'},
];