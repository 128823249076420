import clsx from 'clsx';

const LogoIcon = ({size = 'w-16 h-16', className, ...props}) => (
  <svg
    className={clsx(size, className)}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 66"
    {...props}
  >
    <path
      d="M15.583 12.922c.672-1.721 1.673-3.087 3.001-4.098a8.677 8.677 0 0 1 4.59-1.747c1.481-.13 2.806.059 3.976.569a6.702 6.702 0 0 1 2.785 2.167l-.257-3.004 6.436-.569 1.839 21.223-6.436.57-.261-3.005a7.03 7.03 0 0 1-2.409 2.626c-1.063.708-2.336 1.128-3.817 1.26a8.497 8.497 0 0 1-4.783-.937c-1.485-.777-2.707-1.96-3.666-3.55-.959-1.588-1.533-3.473-1.723-5.653-.187-2.18.054-4.132.725-5.852Zm13.347.775a4.53 4.53 0 0 0-1.575-.907 4.496 4.496 0 0 0-3.538.303 4.552 4.552 0 0 0-1.401 1.16c-.81 1.01-1.146 2.323-1.005 3.94.14 1.62.697 2.867 1.671 3.744.456.412.991.725 1.571.92a4.454 4.454 0 0 0 3.546-.302 4.512 4.512 0 0 0 1.395-1.175c.804-1.017 1.138-2.337 1.002-3.96-.137-1.623-.692-2.864-1.666-3.723ZM49.428.57l-2.437 28.144-6.436-.57L42.99 0l6.437.57ZM60.575.57 58.14 28.714l-6.436-.57L54.14 0l6.436.57ZM10.82 34.744c1.183-.51 2.502-.7 3.957-.571a8.677 8.677 0 0 1 4.589 1.748c1.33 1.008 2.331 2.374 3.002 4.098.671 1.723.913 3.673.726 5.85-.188 2.185-.762 4.07-1.723 5.655-.96 1.584-2.182 2.767-3.665 3.547a8.574 8.574 0 0 1-4.822.934c-1.482-.13-2.748-.543-3.8-1.236a6.906 6.906 0 0 1-2.39-2.608l-.258 2.967L0 54.56l2.438-28.146 6.438.57-.86 9.926a6.637 6.637 0 0 1 2.804-2.166Zm4.719 6.607a4.44 4.44 0 0 0-1.41-1.179 4.391 4.391 0 0 0-1.763-.501 4.321 4.321 0 0 0-1.807.196 4.358 4.358 0 0 0-1.57.923c-.973.86-1.53 2.1-1.67 3.723-.14 1.623.194 2.943 1.004 3.96a4.487 4.487 0 0 0 6.532.558c.986-.87 1.549-2.117 1.69-3.74.14-1.622-.195-2.936-1.006-3.94ZM35.588 34.77a7.263 7.263 0 0 1 3.778-1.008v6.911h-1.775c-1.613 0-2.823.35-3.628 1.05-.806.7-1.209 1.928-1.209 3.684v9.89h-6.461V33.994h6.461v3.552a8.565 8.565 0 0 1 2.834-2.776ZM64.002 33.988 50.78 65.41h-6.955l4.838-10.84-8.577-20.582h7.216l4.874 13.325 4.836-13.325h6.99Z"
      fill="#FCB96B"
    />
  </svg>
);

export default LogoIcon;
