import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {Eye, Search} from '@assets/icons';
import {AnimatePresence, motion} from 'framer-motion';
import styles from './Input.module.scss';

// Add new icon to this object
const icons = {
  eye: Eye,
  search: Search,
};

const Icon = ({icon, ...props}) => {
  const ViewIcon = icons[icon];
  return <ViewIcon {...props} />;
};

const Input = ({
  className,
  innerClassName,
  type = 'text',
  icon,
  iconPosition,
  error,
  help,
  onChange,
  variant,
  placeholder,
  ...props
}) => {
  const [visible, setVisible] = useState(type === 'text');
  const inputIcon = icon || (type === 'password' ? 'eye' : null);
  const {t} = useTranslation();


  return (
    <div className={clsx(className, 'rounded relative')}>
      <div className={clsx(variant ? styles[variant] : styles.input, 'border relative border-solid border-transparent', innerClassName, error && 'border-light-red')}>
        {inputIcon && iconPosition === 'left' && (
          <div
            onClick={() => { if (icon === 'eye' || type === 'password') setVisible(!visible); }}
            // eslint-disable-next-line no-nested-ternary
            className={clsx('absolute h-full flex items-center justify-center transform -translate-y-2/4 left-1.5 inset-y-2/4 w-12 h-full', (icon === 'eye' || type === 'password')
              ? visible
                ? 'cursor-pointer'
                : 'opacity-25 cursor-pointer'
              : '')}
          >
            <Icon icon={inputIcon} />
          </div>
        )}
        <input type={visible ? 'text' : 'password'} placeholder={t(placeholder)} onChange={(e) => onChange(e.target.value)} {...props} className={variant ? styles[`${variant}-field`] : styles['input-field']} />
        {inputIcon && iconPosition === 'right' && (
          <div
            onClick={() => { if (icon === 'eye' || type === 'password') setVisible(!visible); }}
            // eslint-disable-next-line no-nested-ternary
            className={clsx('absolute flex items-center justify-center transform -translate-y-2/4 right-0 inset-y-2/4 w-12 h-full', (icon === 'eye' || type === 'password')
              ? visible
                ? 'cursor-pointer'
                : 'opacity-25 cursor-pointer'
              : '')}
          >
            <Icon icon={inputIcon} />
          </div>
        )}
      </div>

      <AnimatePresence  mode="wait" initial={false} >
        <motion.div
          key={(error || help) || 'empty'}
          animate={{opacity: 1, x: 0, height: 'auto'}}
          initial={{opacity: 0, x: 10, height: help ? 'auto' : 0}}
          exit={{opacity: 0, x: -10, height: help ? 'auto' : 0}}
          transition={{duration: 0.20}}
          className="absolute"
        >
          {// eslint-disable-next-line no-nested-ternary
          error
            ? <span className="text-light-red text-sm font-light-poppins">{t(error)}</span>
            : (help && !error)
              ? <span className="text-dawn-gray font-light-poppins text-sm">{t(help)}</span>
              : <></>
        }
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf(['eye', 'search']),
  iconPosition: PropTypes.oneOf(['left', 'right']),
  type: PropTypes.oneOf(['text', 'password']),
  error: PropTypes.string,
  onChange: PropTypes.func,
  help: PropTypes.string,
  variant: PropTypes.string,
};

Input.defaultProps = {
  onChange: () => {},
  className: '',
  icon: null,
  iconPosition: 'right',
  type: 'text',
  error: '',
  help: '',
  variant: '',
};

export default Input;
