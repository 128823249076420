import * as React from 'react';

const SvgComponent = (props) => (
  <svg
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.25 6.5h19.5M8.666 6.5V4.332a2.167 2.167 0 0 1 2.167-2.167h4.333a2.167 2.167 0 0 1 2.167 2.167v2.166m3.25 0v15.167a2.167 2.167 0 0 1-2.167 2.167H7.583a2.167 2.167 0 0 1-2.167-2.167V6.499h15.167ZM10.834 11.916v6.5M15.166 11.916v6.5"
      stroke="#857F78"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
