import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const CustomLink = ({
  to, className, children, ...props
}) => {
  const defaultClasses = 'hover:opacity-70 transition duration-30';
  return (
    <Link className={clsx(defaultClasses, className)} to={to} {...props}>
      {children}
    </Link>
  );
};

CustomLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export default CustomLink;
