import {
    gql,
  } from '@apollo/client';
  import GraphQlService from './graphql';

  class externalIntegrationConfigGraphql extends GraphQlService {
    async getExternalIntegrationConfigs() {
      return this.client
        .query({
          query: gql`
            query {
                getAllConfig {
                    id
                    accountId
                    timer
                    runOnce
                    updateMethod
                    configJson
                    active
                    account {
                      id
                      name
                    }
                }
            }
          `,
        })
        .then((result) => result?.data?.getAllConfig);
    }

    async createExternalIntegrationsConfig(data) {
      return this.client
        .mutate({
          mutation: gql`
          mutation {
            createExternalIntegrationsConfig(
              createExternalIntegrationsConfig: {
                accountId: "${data.accountId}",
                timer: "${data.timer}",
                runOnce: ${data.runOnce},
                updateMethod: "${data.updateMethod}",
                configJson: """${data.configJson}"""
              }
            ){
              id
            }
          }
        `,
      })
      .then((result) => result?.data?.createExternalIntegrationsConfig);
    }
    async updateStatusConfig(id, active) {
      return this.client
        .mutate({
          mutation: gql`
          mutation {
            updateStatusConfig(
              updateStatusConfig: {
                id:"${id}"
                active: ${active}
              }
            ){
              id
              accountId
              active
              timer
              runOnce
              updateMethod
              configJson
              account{
                id
                name
              }
            }
          }
        `,
      })
      .then((result) => result?.data?.updateStatusConfig);
    }
    async updateExternalIntegrationsConfig(id, data) {
      return this.client
        .mutate({
          mutation: gql`
          mutation {
            updateExternalIntegrationsConfig(
              updateExternalIntegrationsConfig: {
                id:"${id}"
                accountId: "${data.accountId}",
                timer: "${data.timer}",
                runOnce: ${data.runOnce},
                updateMethod: "${data.updateMethod}",
                configJson: """${data.configJson}"""
              }
            ){
              id
              accountId
              active
              timer
              runOnce
              updateMethod
              configJson
              account{
                id
                name
              }
            }
          }
        `,
      })
      .then((result) => result?.data?.updateExternalIntegrationsConfig);
    }
}
const externalIntegrationConfigService = new externalIntegrationConfigGraphql();
export default externalIntegrationConfigService; 
