function SvgComponent() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 233 221"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.8283 3.30005C51.2515 2.3027 55.0469 2.66322 58.2145 4.38162C61.4235 6.09069 63.5033 9.05348 64.4541 13.27C65.3303 17.1558 64.7339 20.4831 62.6649 23.2519C60.628 25.9701 57.5266 27.8205 53.3607 28.8033L54.0993 33.5238L44.7982 35.621L41.8876 24.1575L45.6081 23.3186C48.7911 22.6009 51.1208 21.6629 52.5973 20.5047C54.115 19.3371 54.6409 17.7198 54.1748 15.6529C53.8486 14.2061 53.1996 13.1578 52.2277 12.5082C51.2559 11.8585 50.0672 11.6921 48.6617 12.009C47.1735 12.3446 46.1139 13.0396 45.4829 14.0942C44.8426 15.1073 44.6855 16.3374 45.0117 17.7842L35.0286 20.0352C34.3773 17.5322 34.3814 15.1421 35.0408 12.8649C35.7415 10.5783 37.0775 8.60462 39.0488 6.94382C41.0521 5.23235 43.6453 4.01776 46.8283 3.30005ZM53.208 50.6663C51.3478 51.0858 49.6971 50.8932 48.2559 50.0888C46.8468 49.2336 45.9604 47.9999 45.5969 46.3878C45.2241 44.7342 45.4906 43.2189 46.3964 41.8418C47.3435 40.4553 48.7472 39.5524 50.6074 39.133C52.4263 38.7228 54.0403 38.9453 55.4494 39.8005C56.8999 40.6463 57.8116 41.896 58.1844 43.5495C58.5479 45.1617 58.2561 46.661 57.309 48.0475C56.3939 49.3832 55.0269 50.2562 53.208 50.6663Z"
        fill="#FCB96B"
      />
      <path
        d="M11.1815 37.957C14.5169 37.0633 17.4207 37.2399 19.893 38.4867C22.3965 39.7252 24.083 41.9668 24.9524 45.2115C25.7536 48.2017 25.4138 50.7987 23.9329 53.0025C22.4746 55.1662 20.1756 56.6857 17.0358 57.5611L17.7609 61.2025L10.7473 63.0818L8.13538 54.2692L10.9408 53.5175C13.3411 52.8744 15.0836 52.0835 16.1685 51.1451C17.2846 50.1982 17.6296 48.9295 17.2034 47.339C16.9051 46.2256 16.3746 45.4302 15.6118 44.9526C14.8491 44.4751 13.9378 44.3784 12.878 44.6623C11.7558 44.963 10.9725 45.5309 10.528 46.366C10.0751 47.1692 9.99775 48.1276 10.2961 49.2409L2.76811 51.258C2.18582 49.3343 2.10667 47.4803 2.53065 45.6961C2.9858 43.9035 3.93568 42.3363 5.38029 40.9946C6.84755 39.6127 8.78129 38.6002 11.1815 37.957ZM17.6718 74.5232C16.2691 74.8991 15.0049 74.7946 13.8793 74.2097C12.7764 73.5847 12.0587 72.6519 11.7263 71.4112C11.3853 70.1388 11.5362 68.9562 12.1789 67.8635C12.8527 66.7624 13.891 66.0239 15.2937 65.648C16.6653 65.2805 17.9025 65.4093 19.0055 66.0343C20.1396 66.651 20.8771 67.5955 21.2181 68.8679C21.5505 70.1085 21.3798 71.2794 20.7059 72.3805C20.0547 73.4415 19.0434 74.1557 17.6718 74.5232Z"
        fill="#FFE7AE"
      />
      <path
        d="M132.99 60.613C67.9928 52.5151 26.4679 127.389 20.5587 138.023C10.4231 156.29 -5.19827 184.452 1.70699 203.454C4.10373 210.069 10.4578 218.922 99.3261 220.966C111.326 221.233 134.478 219.92 180.788 217.279C216.532 215.241 224.438 214.336 229.256 205.297C241.527 182.346 221.602 129.893 198.285 100.238C171.641 66.3567 139.988 61.4852 132.99 60.613Z"
        fill="#FCB96B"
      />
      <path
        d="M127.414 100.22C124.473 97.9447 121.832 102.433 124.237 104.572C127.083 106.9 129.87 102.267 127.414 100.22Z"
        fill="#333333"
      />
      <path
        d="M108.029 100.219C105.243 97.9457 102.73 102.432 105.017 104.569C107.714 106.904 110.343 102.265 108.029 100.219Z"
        fill="#333333"
      />
    </svg>
  );
}

export default SvgComponent;
