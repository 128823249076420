import config from '@config/config';
import ApiService from './api';

class AccountApi extends ApiService {
  constructor() {
    if (AccountApi.instance) {
      return AccountApi.instance;
    }

    super(config.authApiUrl);
    AccountApi.instance = this;
  }

  async createAccount(data) {
    const response = await this.http.post('/accounts', data);
    return response.data;
  }

  async getAccountById(accountId) {
    const response = await this.http.get(`/accounts/${accountId}`);
    return response.data;
  }

  async getAccounts() {
    const response = await this.http.get('accounts');
    return response.data;
  }

  async update(accountId, data) {
    const response = await this.http.put(`/accounts/${accountId}`, data);
    return response.data;
  }
}

const accountApiInstance = new AccountApi();
export default accountApiInstance;
