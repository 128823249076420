import ReactCalendar from "react-calendar";
import { useTranslation } from "react-i18next";
import { ArrowLeft, ArrowRight } from "@assets/icons";

// import 'react-calendar/dist/Calendar.css';
import clsx from "clsx";
import styles from "./Calendar.module.scss";

const Calendar = ({
  value,
  onChange,
  minDate,
  maxDate,
  withoutShadow = false,
  className,
  disabledChange = false,
  ...props
}) => {
  const { i18n } = useTranslation();

  return (
    <ReactCalendar
      view="month"
      locale={i18n.language === "enUS" ? "en-US" : i18n.language}
      className={clsx(
        styles["custom-calendar"],
        !withoutShadow && styles["with-shadow"],
        disabledChange && styles.disabledChange,
        className
      )}
      onChange={onChange}
      value={value}
      prevLabel={<ArrowLeft />}
      nextLabel={<ArrowRight />}
      prev2Label={null}
      next2Label={null}
      minDate={minDate}
      maxDate={maxDate}
      tileClassName={styles["custom-calendar__tile"]}
      {...props}
    />
  );
};

export default Calendar;
