import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

function ChatIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-8 h-7', className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31 27"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.67.026C8.515.25 4.637 2.066 2.42 4.825 1.351 6.155.711 7.573.445 9.203c-.088.533-.087 1.915 0 2.444.152.914.415 1.753.778 2.477l.17.34-.02.293c-.056.78-.439 2.295-1.094 4.326-.273.846-.315 1.074-.256 1.388.131.704.695 1.256 1.405 1.377.324.056.578.011 1.098-.19C5.145 20.64 7.073 20.05 7.97 19.99c.308-.02.34-.015.712.105.848.275 2.013.52 3.106.652.73.09 2.959.09 3.7.001 3.458-.414 6.48-1.775 8.57-3.858 1.17-1.165 2.042-2.569 2.48-3.988a8.605 8.605 0 00.23-4.022c-.678-3.615-3.712-6.673-8.02-8.084-1.891-.62-3.982-.885-6.078-.771zm2.561 2.353c1.68.197 3.138.618 4.506 1.304 1.115.559 1.94 1.147 2.742 1.954 1.173 1.182 1.848 2.455 2.07 3.908.067.435.068 1.32.002 1.753-.282 1.836-1.358 3.497-3.129 4.832-3.156 2.379-8.01 3.079-12.133 1.75a6.598 6.598 0 00-.622-.173c-.896-.16-2.618.22-5.21 1.15-.418.15-.764.27-.768.265-.003-.004.077-.283.178-.62.551-1.818.872-3.482.809-4.19-.035-.391-.098-.592-.354-1.124-.276-.573-.446-1.063-.542-1.563-.267-1.381-.093-2.665.537-3.95 1.463-2.987 5.08-5.049 9.403-5.358.42-.03 2.073.01 2.511.062zm13.885 10.227c-.145.737-.466 1.68-.832 2.439l-.247.513.045.233a5.92 5.92 0 01.082 1.467c-.08.974-.326 1.737-.866 2.687-.354.623-.462 1.3-.392 2.448.034.55.117 1.317.201 1.855.025.156.021.177-.026.162a29.213 29.213 0 00-2.413-.624c-.766-.156-1.065-.192-1.595-.188-.436.003-.528.014-.798.097-.747.23-1.45.38-2.24.481-.668.086-2.184.086-2.851 0a12.108 12.108 0 01-2.865-.715l-.462-.181-.763-.02a18.232 18.232 0 01-3.297-.376 8.266 8.266 0 00-.565-.105c-.005.005.147.158.34.34 1.827 1.74 4.289 2.89 7.035 3.29 1.992.29 4.215.128 6.092-.444.255-.078.326-.086.606-.068.644.042 2.047.379 3.56.856.998.314 1.283.323 1.813.058.55-.275.91-.85.91-1.458.001-.115-.051-.52-.115-.9-.214-1.27-.31-2.223-.283-2.828l.016-.373.176-.31a8.147 8.147 0 001.08-4.34 7.77 7.77 0 00-.793-3.31c-.158-.332-.477-.915-.501-.915a1.91 1.91 0 00-.052.229z"
      />
    </svg>
  );
}

ChatIcon.propTypes = {
  className: PropTypes.string,
};

ChatIcon.defaultProps = {
  className: 'fill-current text-dawn-gray',
};

export default ChatIcon;
