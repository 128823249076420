import clsx from "clsx";
import * as React from "react";

const SvgComponent = ({className, props}) => (
  <svg
  className={clsx(className || "w-10 h-10")}
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#adadad"
      stroke="none"
    >
      <path
        d="M2884 4511 c-21 -16 -157 -147 -300 -292 -287 -290 -297 -305 -274
   -382 8 -28 35 -65 83 -115 l71 -73 -240 -240 -240 -240 -124 22 c-173 29 -476
   37 -618 15 -194 -30 -368 -104 -475 -205 -27 -25 -55 -58 -63 -73 -17 -32 -18
   -86 -3 -125 5 -15 220 -238 477 -496 l467 -467 -512 -513 c-507 -507 -543
   -547 -543 -609 0 -38 40 -106 74 -128 37 -22 123 -27 159 -9 12 6 253 241 534
   522 l513 512 462 -462 c255 -253 477 -468 495 -477 18 -9 55 -16 82 -16 42 0
   56 5 86 32 50 43 118 141 160 229 101 213 125 557 65 914 l-20 120 239 239
   240 240 78 -76 c64 -62 86 -77 120 -82 76 -13 105 9 390 295 274 276 283 288
   283 374 0 91 23 65 -748 838 -400 401 -739 735 -754 743 -47 25 -119 18 -164
   -15z m1134 -1678 l-141 -142 -75 69 c-125 118 -186 118 -304 1 -209 -207 -642
   -660 -655 -685 -19 -36 -15 -81 23 -266 23 -117 27 -161 28 -325 1 -190 -6
   -249 -41 -355 -8 -25 -17 -47 -20 -50 -5 -5 -1753 1742 -1753 1752 0 12 122
   46 205 57 160 23 398 7 607 -39 104 -24 150 -25 186 -6 15 8 190 178 389 378
   315 317 362 368 368 401 10 54 -9 91 -89 176 l-71 73 150 144 150 144 592
   -593 593 -592 -142 -142z"
      />
    </g>
  </svg>
);

export default SvgComponent;
