import { gql } from "@apollo/client";
import GraphQlService from "./graphql";

class AccountGraphql extends GraphQlService {
  async getAccounts() {
    return this.client
      .query({
        query: gql`
          query {
            findAllAccountsByUserId {
              id
              isUnavailable
              unavailableMessage
              isAnywaySendMessage
              account {
                ssoEnabled
                name
                id
                dtActivation
                dtDeactivation
              }
              user {
                email
                chatChatParticipants {
                  accountId
                  name
                  sendMessageCount
                  readMessageCount
                  chatRoom {
                    messageCount
                    organizerName
                  }
                }
                chatChatParticipantGlobals{
                  name
                  sendMessageCount
                  readMessageCount
                  globalChatRoom{
                    messageCount
                    organizerName
                  }
                }
              }
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((result) => result?.data?.findAllAccountsByUserId);
  }
  async updateAuthUserSession(accountId) {
    return this.client
      .mutate({
        mutation: gql`
            mutation{
              updateAuthUserSession(accountId: "${accountId}")
              {
              accessToken
              userId
              accountId
            }
          }
        `,
      })
      .then((result) => result?.data?.updateAuthUserSession);
  }

  async findChildrenAccounts() {
    return this.client
      .query({
        query: gql`
          query {
            findChildrenAccounts {
              name
              id
            }
          }
        `,
      })
      .then((result) => result?.data?.findChildrenAccounts);
  }

  async findChildrenAccountsByAccountId(accountId) {
    return this.client
      .query({
        query: gql`
        query{
          findChildrenAccountsByAccountId(
            id:"${accountId}"
            ){
            name
            id
          }
        }
        `,
      })
      .then((result) => result?.data?.findChildrenAccountsByAccountId);
  }

  async updateIsUnavailableStatus(isUnavailable) {
    return this.client
      .mutate({
        mutation: gql`
        mutation {
          updateIsUnavailableStatus(
            updateUnavailableStatusInput: { isUnavailable: ${isUnavailable} }
          ) {
            id
          }
        }
        `,
      })
      .then((result) => result?.data?.updateIsUnavailableStatus);
  }

  async updateUnavailableMessage(message) {
    return this.client
      .mutate({
        mutation: gql`
          mutation {
            updateUnavailableMessage(
              updateUnavailableMessageInput: { unavailableMessage: """${message}""" }
            ) {
              id
            }
          }
        `,
      })
      .then((result) => result?.data?.updateUnavailableMessage);
  }

  async updateIsAnywaySendMessageStatus(isAnywaySendMessage) {
    return this.client
      .mutate({
        mutation: gql`
        mutation {
          updateIsAnywaySendMessageStatus(
            updateAnywaySendMessageStatusInput: { isAnywaySendMessage: ${isAnywaySendMessage} }
          ) {
            id
          }
        }
        `,
      })
      .then((result) => result?.data?.updateIsAnywaySendMessageStatus);
  }
}

const AccountGraphqlInstance = new AccountGraphql();
export default AccountGraphqlInstance;
