import clsx from "clsx";

function PlusSignIcon({ className, ...props }) {
  return (
    <svg
      fill="none"
      height="800px"
      width="800px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={clsx("w-6 h-6", className)}
      {...props}
    >
      <g>
        <g >
        <path fillRule="evenodd" clipRule="evenodd" d="M13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4V11H4C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13H11V20C11 20.5523 11.4477 21 12 21C12.5523 21 13 20.5523 13 20V13H20C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11H13V4Z" fill="#B8B8B8"/>
        </g>
      </g>
    </svg>
  );
}

export default PlusSignIcon;
