import * as React from 'react';
import clsx from 'clsx';

const FilterIcon = ({className, ...props}) => (
  <svg
    className={clsx('w-5 h-5', className)}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.165.75H.832l7.333 8.672v5.995l3.667 1.833V9.422L19.165.75Z"
      stroke="#585550"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FilterIcon;
