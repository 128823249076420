import * as React from 'react';
import clsx from 'clsx';

const SettingsNavbarIcon = ({className, ...props}) => (
  <svg
    className={clsx('w-6 h-6', className)}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 18a5 5 0 1 1 0-10 5 5 0 0 1 0 10Zm0-8a3 3 0 1 0 0 5.999A3 3 0 0 0 13 10Zm.667 16h-1.334a3.002 3.002 0 0 1-3-3 .484.484 0 0 0-.293-.453h-.01a.44.44 0 0 0-.48.096 3.055 3.055 0 0 1-4.267.028l-.934-.942a3.001 3.001 0 0 1 0-4.249.488.488 0 0 0 .103-.524.45.45 0 0 0-.412-.285A3.013 3.013 0 0 1 0 13.667v-1.334a3.003 3.003 0 0 1 3-3 .48.48 0 0 0 .452-.293v-.01a.443.443 0 0 0-.096-.48 3 3 0 0 1-.028-4.267l.945-.946a3.067 3.067 0 0 1 4.243 0 .488.488 0 0 0 .533.115.445.445 0 0 0 .283-.41A3.01 3.01 0 0 1 12.333 0h1.334a3.003 3.003 0 0 1 3 3 .456.456 0 0 0 .28.444.483.483 0 0 0 .51-.084 2.988 2.988 0 0 1 2.14-.901 2.98 2.98 0 0 1 2.122.878l.941.934a2.998 2.998 0 0 1 0 4.242.457.457 0 0 0-.121.514l.008.018a.442.442 0 0 0 .409.28A3.011 3.011 0 0 1 26 12.333v1.334a3.002 3.002 0 0 1-3 3 .456.456 0 0 0-.444.28.487.487 0 0 0 .084.51 3 3 0 0 1 .023 4.267l-.934.941a3.066 3.066 0 0 1-4.242 0 .458.458 0 0 0-.514-.121.47.47 0 0 0-.298.417A3.01 3.01 0 0 1 13.667 26ZM9.92 20.757a2.49 2.49 0 0 1 1.412 2.206 1.011 1.011 0 0 0 1 1.037h1.334a1 1 0 0 0 1-1 2.494 2.494 0 0 1 1.533-2.305 2.432 2.432 0 0 1 2.657.533 1.007 1.007 0 0 0 1.443.028l.941-.933a1.002 1.002 0 0 0 0-1.418 2.499 2.499 0 0 1-.55-2.68v-.018l.009-.023a2.428 2.428 0 0 1 2.255-1.507 1.007 1.007 0 0 0 1.034-1v-1.333a1 1 0 0 0-1-1 2.45 2.45 0 0 1-2.292-1.507 2.448 2.448 0 0 1 .527-2.704 1.021 1.021 0 0 0 .321-.736.99.99 0 0 0-.293-.706l-.933-.942a1 1 0 0 0-1.416.003 2.504 2.504 0 0 1-2.68.55L16.2 5.3l-.023-.01a2.428 2.428 0 0 1-1.506-2.254 1.01 1.01 0 0 0-1-1.035h-1.334a1 1 0 0 0-1 1 2.45 2.45 0 0 1-1.526 2.299 2.481 2.481 0 0 1-2.678-.523 1.01 1.01 0 0 0-1.44-.028l-.945.945a1 1 0 0 0 0 1.415A2.45 2.45 0 0 1 5.305 9.8a2.49 2.49 0 0 1-2.268 1.533 1.011 1.011 0 0 0-1.037 1v1.334a1 1 0 0 0 1 1 2.444 2.444 0 0 1 2.29 1.512l.009.017.006.019a2.493 2.493 0 0 1-.533 2.666 1 1 0 0 0-.02 1.434l.941.933a1.024 1.024 0 0 0 1.415 0 2.452 2.452 0 0 1 2.692-.553c.042.018.082.04.121.062Z"
      fillOpacity={0.8}
    />
  </svg>
);

export default SettingsNavbarIcon;
