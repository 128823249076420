import * as React from 'react';
import clsx from 'clsx';

function MicrophoneCrossedIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-6 h-6', className)}
      fill="none"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 16.615c3.293 0 5.972-2.623 5.972-5.846v-3.31l4.752-4.652a.91.91 0 000-1.306.958.958 0 00-1.334 0L.276 21.193a.91.91 0 000 1.306.951.951 0 001.333 0l3.923-3.841a9.829 9.829 0 004.525 1.6v1.896H5.972a.933.933 0 00-.943.923c0 .51.423.923.943.923H16.03c.52 0 .943-.413.943-.923a.934.934 0 00-.943-.923h-4.086V20.26c4.932-.466 8.8-4.544 8.8-9.491a.933.933 0 00-.943-.923.933.933 0 00-.942.923c0 4.24-3.525 7.692-7.857 7.692a7.925 7.925 0 01-4.091-1.15l1.378-1.35a6.03 6.03 0 002.713.653zm4.086-5.846c0 2.205-1.833 4-4.085 4-.438 0-.868-.076-1.28-.211l5.365-5.253v1.464zm-9.91 1.294a6.192 6.192 0 01-.147-1.294V5.846c0-1.548.619-3.015 1.743-4.13A6.057 6.057 0 0111 0c1.953 0 3.785.94 4.897 2.514a.91.91 0 01-.239 1.284.954.954 0 01-1.31-.234A4.097 4.097 0 0011 1.844 4.155 4.155 0 008.104 3.02a3.973 3.973 0 00-1.189 2.826v4.923c0 .281.035.582.106.92a.924.924 0 01-.733 1.09.935.935 0 01-1.112-.715zm-2.83 3.094a9.372 9.372 0 01-1.088-4.388c0-.51.422-.923.943-.923.52 0 .943.413.943.923 0 1.239.301 2.461.873 3.537a.913.913 0 01-.4 1.244.946.946 0 01-.436.105.944.944 0 01-.836-.498z"
        fill="#fff"
      />
    </svg>
  );
}

export default MicrophoneCrossedIcon;
