import * as React from 'react';
import clsx from 'clsx';

function FileTextIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-7 h-7', className)}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.334 2.333H7a2.333 2.333 0 00-2.333 2.333v18.667A2.333 2.333 0 007 25.666h14a2.333 2.333 0 002.334-2.333v-14l-7-7z"
        stroke="#505050"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.333 2.333v7h7M18.666 15.167H9.333M18.666 19.833H9.333M11.666 10.5H9.333"
        stroke="#505050"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FileTextIcon;
