import clsx from 'clsx';

function TemplateIcon({ className, color, ...props }) {
  return (
    <>
      <svg className={clsx('w-7 h-7', className)} xmlns="http://www.w3.org/2000/svg" version="1.0" width="32px" height="32px" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#585550" stroke="none">
          <path d="M2155 5111 c-85 -14 -137 -31 -220 -71 -152 -73 -263 -186 -340 -345 -79 -164 -75 -92 -75 -1379 l0 -1145 -103 -103 c-213 -213 -329 -456 -358 -755 -29 -295 63 -604 252 -849 94 -122 267 -263 414 -337 78 -39 216 -85 312 -103 117 -23 329 -23 446 0 532 103 922 532 977 1073 20 196 -16 417 -99 605 -58 132 -142 250 -258 366 l-103 103 0 1140 c0 1282 3 1221 -75 1384 -75 157 -188 271 -340 345 -44 21 -108 46 -142 54 -75 20 -220 28 -288 17z m237 -311 c157 -48 277 -184 308 -349 6 -35 10 -471 10 -1228 0 -1026 2 -1178 15 -1203 8 -16 45 -54 83 -83 231 -186 347 -409 359 -692 11 -273 -83 -505 -285 -696 -175 -166 -381 -249 -622 -249 -239 0 -448 84 -622 249 -188 178 -288 405 -288 657 0 290 124 540 362 731 38 29 75 67 83 83 13 25 15 177 15 1203 0 757 4 1193 10 1228 50 265 317 428 572 349z" />
          <path d="M2183 3900 c-18 -10 -40 -31 -50 -47 -17 -26 -18 -85 -23 -1041 l-5 -1013 -65 -26 c-123 -49 -239 -147 -303 -255 -112 -192 -107 -452 13 -635 114 -174 302 -277 505 -277 50 0 115 6 145 13 140 33 287 138 370 264 95 145 121 348 66 511 -34 100 -65 153 -133 227 -65 70 -147 126 -228 155 l-60 22 -5 1014 c-5 956 -6 1015 -23 1041 -27 42 -75 67 -127 67 -27 0 -58 -8 -77 -20z m178 -2397 c78 -25 168 -116 193 -197 47 -150 -21 -308 -162 -378 -67 -33 -197 -33 -264 0 -140 69 -208 225 -163 377 13 48 28 70 78 120 92 91 196 117 318 78z" />
          <path d="M3395 4510 c-11 -4 -33 -22 -50 -40 -27 -28 -30 -38 -30 -95 0 -54 4 -67 25 -90 50 -54 58 -55 345 -55 296 0 320 4 356 64 30 50 33 88 11 140 -38 84 -45 86 -362 85 -151 0 -284 -4 -295 -9z" />
          <path d="M3395 3910 c-11 -4 -33 -22 -50 -40 -27 -28 -30 -38 -30 -95 0 -54 4 -67 25 -90 50 -54 58 -55 345 -55 296 0 320 4 356 64 30 50 33 88 11 140 -38 84 -45 86 -362 85 -151 0 -284 -4 -295 -9z" />
          <path d="M3395 3310 c-11 -4 -33 -22 -50 -40 -27 -28 -30 -38 -30 -95 0 -61 2 -67 38 -102 l37 -38 280 -3 c312 -3 333 0 371 62 30 50 33 88 11 140 -38 84 -45 86 -362 85 -151 0 -284 -4 -295 -9z" />
          <path d="M3395 2710 c-11 -4 -33 -22 -50 -40 -27 -28 -30 -38 -30 -95 0 -61 2 -67 38 -102 l37 -38 288 -3 c274 -2 288 -1 320 18 33 21 72 87 72 125 0 38 -38 104 -72 125 -31 19 -49 20 -308 19 -151 0 -284 -4 -295 -9z" />
        </g>
      </svg>
    </>
  );
}

export default TemplateIcon;
