import {BaseLink} from '@components/Form';
import {Logo} from '@assets/icons';
import {ADMIN_ROOT} from '@admin/router/consts';
import { Outlet } from 'react-router-dom';

const AuthAdmin = ({children}) => {
  return(
  <div className="bg-main min-h-screen flex justify-center items-center relative">
    <BaseLink to={ADMIN_ROOT} className="absolute top-10 left-10 pointer-events-none">
      <Logo />
    </BaseLink>
    <Outlet />
  </div>
)};
export default AuthAdmin;
