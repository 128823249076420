import * as React from 'react';
import clsx from 'clsx';

function SmallArrowIcon({className, stroke = '#999', ...props}) {
  return (
    <svg
      className={clsx('w-2 h-3', className)}
      fill="none"
      viewBox="0 0 8 12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 11l5-5-5-5"
        stroke={stroke}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SmallArrowIcon;
