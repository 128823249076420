import { Outlet } from "react-router-dom";

const CheckInLayout = ({ route }) => {
  return (
    <div className="bg-main flex relative">
      <Outlet />
    </div>
  );
};
export default CheckInLayout;
