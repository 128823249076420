import clsx from "clsx";
import { useState } from "react";

const ProfileVideoItem = ({ profileVideoUrl, profileVideoStyle ,controlsAllowed,isViewMode ,style ,autoPlayAllowed}) => {
  const [videoError, setVideoError] = useState(false);

  if (!profileVideoUrl) {
    return null;
  }

  const result = !videoError ? (
    <>
      <video
        onError={() => setVideoError(true)}
        className={clsx(
          profileVideoStyle.className,
          isViewMode ? "" : "rounded-full object-cover"
        )}
        controls= {controlsAllowed}
        autoPlay={autoPlayAllowed}
        style={style}
      >
        <source src={profileVideoUrl} />
      </video>
    </>
  ) : null;

  return result;
};

const ProfileVideo = ({
  profileVideoUrl,
  profileVideoStyle = {
    className: "w-16 h-16"
  },
  controlsAllowed=true,
  isViewMode=true,
  style=null,
  autoPlayAllowed=true
}) => {
  return (
    <div className="relative" style={style} >
      <ProfileVideoItem
        profileVideoUrl={profileVideoUrl}
        profileVideoStyle={profileVideoStyle}
        controlsAllowed={controlsAllowed}
        isViewMode={isViewMode}
        style={style}
        autoPlayAllowed={autoPlayAllowed}
      />
    </div>
  );
};

export default ProfileVideo;
