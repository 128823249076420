function SurveyOutroAllbry() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 315 244"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_132_14083)">
        <path
          d="M25.4527 105.663C13.7234 111.68 -0.366324 122.179 0.00549709 135.835C0.0787345 138.554 0.399897 142.793 0.191452 154.918C-0.484587 194.998 1.38577 235.736 0.0336928 242.564C0.0336928 242.564 296.651 245.238 297.868 243.29C324.757 200.306 319.636 127.932 284.972 102.263C273.705 93.9316 242.951 78.2262 211.797 84.407C206.772 85.409 174.023 91.9051 153.917 118.289C146.39 128.163 143.033 137.676 134.368 138.824C124.74 140.096 118.633 129.705 107.991 120.969C85.3665 102.404 48.9112 93.6332 25.4527 105.663Z"
          fill="#FCB96B"
        />
        <path
          d="M206.259 136.252C199.302 137.035 194.192 145.822 196.851 152.847C199.031 158.623 206.22 162.642 212.355 160.041C218.095 157.604 220.135 150.533 218.445 145.101C216.822 139.906 211.617 135.644 206.259 136.252Z"
          fill="#AB85DE"
        />
        <path
          d="M265.813 127.156C258.855 127.944 253.74 136.725 256.399 143.756C258.579 149.532 265.773 153.545 271.903 150.95C277.649 148.513 279.688 141.442 277.993 136.01C276.376 130.814 271.165 126.553 265.813 127.156Z"
          fill="#AB85DE"
        />
        <path
          d="M243.506 120.893C238.857 117.653 234.681 124.045 238.483 127.091C242.984 130.405 247.389 123.807 243.506 120.893Z"
          fill="#333333"
        />
        <path
          d="M225.516 122.892C220.866 119.655 216.672 126.043 220.488 129.087C224.99 132.412 229.378 125.805 225.516 122.892Z"
          fill="#333333"
        />
      </g>
      <g clip-path="url(#clip1_132_14083)">
        <path
          d="M192.466 24.204C193.055 24.0191 195.11 23.4022 197.082 24.3954C199.753 25.7416 200.926 29.3754 200.635 32.2186C200.355 34.9274 199.447 35.1237 195.392 42.8626C194.914 43.7781 194.119 44.7188 194.099 44.7784C194.089 44.8109 194.074 44.8414 194.054 44.8684C193.889 45.0822 193.413 44.9533 193.016 44.8614C192.18 44.6696 192.039 44.7139 191.712 44.6242C188.176 43.6323 182.509 43.32 178.227 39.3431C177.086 38.2815 176.638 37.2268 176.565 35.7437C176.427 32.9754 179.23 31.0831 179.428 30.9724C181.329 29.9286 183.166 30.0907 183.801 30.1482C185.337 30.2802 186.374 30.8636 186.585 31.0205L187.008 31.3389C187.008 31.3389 188.114 25.5249 192.466 24.204Z"
          fill="#FFD4D4"
        />
      </g>
      <g clip-path="url(#clip2_132_14083)">
        <path
          d="M300.852 37.8224C301.386 38.1307 303.231 39.2278 303.833 41.352C304.648 44.23 302.764 47.5513 300.473 49.2598C298.288 50.8854 297.529 50.3494 289.086 52.5961C288.088 52.8629 286.858 52.9133 286.8 52.9389C286.77 52.9541 286.737 52.9636 286.704 52.967C286.434 52.9897 286.208 52.5524 286.007 52.1976C285.583 51.452 285.455 51.378 285.3 51.0763C283.639 47.8009 280.037 43.4151 280.071 37.5717C280.081 36.0127 280.555 34.9695 281.598 33.9125C283.544 31.9387 286.834 32.7245 287.049 32.7954C289.103 33.4895 290.226 34.9521 290.613 35.4591C291.555 36.68 291.826 37.8382 291.853 38.0997L291.905 38.6268C291.905 38.6268 296.936 35.5096 300.852 37.8224Z"
          fill="#FFD5D5"
        />
      </g>
      <g clip-path="url(#clip3_132_14083)">
        <path
          d="M252.497 2.06459C253.497 2.13133 256.972 2.40863 259.372 5.07416C262.623 8.68603 262.257 14.8784 260.141 19.0114C258.121 22.9474 256.631 22.7097 245.932 32.0367C244.669 33.1409 242.911 34.0965 242.845 34.175C242.812 34.2187 242.771 34.256 242.725 34.2851C242.348 34.5112 241.705 34.0361 241.157 33.6628C240.005 32.8797 239.765 32.8637 239.322 32.5351C234.554 28.9501 226.158 25.1379 222.02 16.5944C220.917 14.3145 220.86 12.4537 221.623 10.1652C223.045 5.89282 228.405 4.68016 228.77 4.62914C232.262 4.16876 234.948 5.49641 235.876 5.95791C238.124 7.06289 239.072 8.0866 239.299 8.44851L240.391 9.35874C240.391 9.35874 245.13 1.49983 252.497 2.06459Z"
          fill="#FF9F9F"
        />
      </g>
      <defs>
        <clipPath id="clip0_132_14083">
          <rect
            width="315"
            height="161"
            fill="white"
            transform="translate(0 83)"
          />
        </clipPath>
        <clipPath id="clip1_132_14083">
          <rect
            width="25.1221"
            height="20.389"
            fill="white"
            transform="translate(174.398 29.8018) rotate(-17.4465)"
          />
        </clipPath>
        <clipPath id="clip2_132_14083">
          <rect
            width="25.1221"
            height="20.389"
            fill="white"
            transform="translate(284.51 28.2979) rotate(30)"
          />
        </clipPath>
        <clipPath id="clip3_132_14083">
          <rect
            width="40.8127"
            height="33.1234"
            fill="white"
            transform="translate(221.752 0.0605469) rotate(3.81975)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SurveyOutroAllbry;
