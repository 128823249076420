import {
    createContext, useEffect, useRef, useContext, useState,
  } from 'react';
  import {io} from 'socket.io-client';
  
  import config from '@config/config';
  import LocalStorageService from '@services/localStorage';
  import {USER_ROLES} from '@utils/consts';
  import {UserContext} from './User';
  
  export const GlobalChatContext = createContext({
    socketForGlobalMessage: null,
    openedChatRoomId: undefined,
    setOpenedChatRoomId: () => {},
  });
  
  const GlobalChatProvider = ({children}) => {
    const {profile: currentProfile} = useContext(UserContext);
    const [openedChatRoomId, setOpenedChatRoomId] = useState();
    const socketForGlobalMessage = useRef();
  
    useEffect(() => {
      if (!currentProfile
        || (currentProfile.type !== USER_ROLES.STUDENT_ROLE
          && currentProfile.type !== USER_ROLES.COUNSELLOR_ROLE)
           || (socketForGlobalMessage.current && socketForGlobalMessage.current.connected)) {
        return undefined;
      }
  
      const accessToken = LocalStorageService.getAccessToken();
  
      if (!accessToken) {
        return undefined;
      }
  
      socketForGlobalMessage.current = io(config.globalChatSocketDomain, {
          transports: ['websocket'],
          path:config.globalChatSocketPath,
          query: {
            accessToken,
          },
        });
      
  return () => {
        if (socketForGlobalMessage?.current && socketForGlobalMessage.current.connected) {
          socketForGlobalMessage.current.disconnect();
        }
      };
    }, [currentProfile?.id]);
  
    const value = {socketForGlobalMessage, openedChatRoomId, setOpenedChatRoomId};
  
    return (
      <GlobalChatContext.Provider value={{...value}}>{children}</GlobalChatContext.Provider>
    );
  };
  
  export default GlobalChatProvider;
  