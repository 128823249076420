import * as React from 'react';
import clsx from 'clsx';

const CameraBlockIcon = ({className, ...props}) => (
  <svg
    className={clsx('w-7 h-6', className)}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={17.568} height={15.46} rx={1.405} fill="#585550" />
    <path
      d="M21.784 2.254a.351.351 0 0 0-.6-.249l-5.476 5.476a.351.351 0 0 0 0 .497l5.476 5.476c.222.221.6.065.6-.248V2.254Z"
      fill="#585550"
    />
    <rect
      x={13.702}
      y={10.19}
      width={11.946}
      height={11.946}
      rx={1.757}
      fill="#BB5947"
      stroke="#fff"
      strokeWidth={0.703}
    />
    <path
      d="m16.806 13.293 2.869 2.87m0 0 2.87 2.869m-2.87-2.87 2.87-2.869m-2.87 2.87-2.87 2.869"
      stroke="#fff"
      strokeWidth={1.054}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CameraBlockIcon;
