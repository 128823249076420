import React from 'react';
import clsx from 'clsx';

function InboxIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-5 h-5', className)}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5 6.75C5 6.336 5.336 6 5.75 6H13.75C14.164 6 14.5 6.336 14.5 6.75C14.5 7.164 14.164 7.5 13.75 7.5H5.75C5.336 7.5 5 7.164 5 6.75ZM6.75 4.5H12.75C13.164 4.5 13.5 4.164 13.5 3.75C13.5 3.336 13.164 3 12.75 3H6.75C6.336 3 6 3.336 6 3.75C6 4.164 6.336 4.5 6.75 4.5ZM19.5 10.2371V12.75C19.5 15.369 17.369 17.5 14.75 17.5H4.75C2.131 17.5 0 15.369 0 12.75V10.2371C0 9.83306 0.0650166 9.43403 0.192017 9.05103L2.12701 3.24805C2.77401 1.30605 4.585 0 6.633 0H12.867C14.915 0 16.725 1.30505 17.373 3.24805L19.308 9.05103C19.435 9.43303 19.5 9.83206 19.5 10.2371ZM1.78998 9H6.75C7.164 9 7.5 9.336 7.5 9.75C7.5 10.991 8.509 12 9.75 12C10.991 12 12 10.991 12 9.75C12 9.336 12.336 9 12.75 9H17.71L15.951 3.72205C15.508 2.39305 14.269 1.5 12.868 1.5H6.63397C5.23297 1.5 3.99403 2.39305 3.55103 3.72205L1.78998 9ZM18 12.75V10.5H13.425C13.076 12.209 11.561 13.5 9.75 13.5C7.939 13.5 6.42401 12.209 6.07501 10.5H1.5V12.75C1.5 14.542 2.958 16 4.75 16H14.75C16.542 16 18 14.542 18 12.75Z" fill="white" />
    </svg>
  );
}

export default InboxIcon;
