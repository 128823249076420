import Skeleton from 'react-loading-skeleton';

export const loadableAuthParams = {
  fallback: (
    <Skeleton
      containerClassName="h-screen w-screen"
      style={{
        width: '100%',
        height: '100%',
      }}
    />
  ),
};

export const loadableRootParams = {
  fallback: (
    <Skeleton
      containerClassName="h-full w-full"
      style={{
        width: '100%',
        height: '100%',
      }}
    />
  ),
};
