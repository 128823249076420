import React from 'react';
import styles from './Loader.module.scss';

const Loader = ({
  height = 100, width = 100, stroke = 'var(--colors-primary)', ...props
}) => (
  <div {...props}>
    <div className="relative">
      <svg
        className={styles.rotate}
        fill="none"
        height={height}
        viewBox="0 0 115 115"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M57.742 10.052c-26.338 0-47.688 21.35-47.688 47.688s21.35 47.688 47.688 47.688 47.688-21.35 47.688-47.688"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={9.538}
        />
      </svg>
      <svg
        className={styles.reverse}
        fill="none"
        height={height}
        viewBox="0 0 115 115"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M57.739 86.353c15.801 0 28.613-12.812 28.613-28.613 0-15.802-12.812-28.613-28.613-28.613-15.802 0-28.613 12.811-28.613 28.613"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={9.538}
        />
      </svg>
    </div>
  </div>
);

export default Loader;
