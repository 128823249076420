function SurveyIntroAllbry() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 307 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3_1315)">
        <path
          d="M24.8063 21.959C13.3749 27.7897 -0.356942 37.962 0.00543615 51.1943C0.0768136 53.8288 0.389819 57.9359 0.186668 69.6846C-0.472201 108.52 1.35065 147.993 0.0329158 154.609C0.0329158 154.609 289.117 157.2 290.303 155.312C316.51 113.663 311.519 43.5365 277.735 18.6646C266.754 10.5922 236.781 -4.62555 206.418 1.36333C201.52 2.33421 169.604 8.62857 150.008 34.1931C142.673 43.7601 139.4 52.9779 130.956 54.0906C121.572 55.3233 115.62 45.2546 105.249 36.7894C83.1986 18.8009 47.6691 10.303 24.8063 21.959Z"
          fill="#FCB96B"
        />
        <path
          d="M243.506 37.8928C238.857 34.6531 234.681 41.0449 238.483 44.0908C242.984 47.4054 247.389 40.8075 243.506 37.8928Z"
          fill="#333333"
        />
        <path
          d="M225.516 39.8922C220.866 36.6545 216.672 43.0425 220.488 46.0866C224.99 49.4118 229.378 42.8052 225.516 39.8922Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_1315">
          <rect width="307" height="156" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SurveyIntroAllbry;
