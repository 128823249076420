import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n";
import UserProvider from "./contexts/User";
import ChatProvider from "./contexts/Messages";
import AppointmentReportProvider from "./contexts/AppointmentReport";
import AdminApp from "./_admin";

// ***** PrimeReact *****
import "primereact/resources/themes/tailwind-light/theme.css"; // Theme : tailwind-light
import "primereact/resources/primereact.min.css"; // PrimeReact Core css
import "primeicons/primeicons.css"; // PrimeReact Icons
import { AbilityContext } from "ability/Can";
import { ability } from "ability/ability";
import GlobalChatProvider from "@contexts/GlobalMessages";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (process.env.REACT_APP_TYPE === "admin") {
  root.render(<AdminApp />);
} else {
  root.render(
    <AbilityContext.Provider value={ability}>
      <BrowserRouter>
        <UserProvider>
          <AppointmentReportProvider>
            <ChatProvider>
              <GlobalChatProvider>
                <App />
              </GlobalChatProvider>
            </ChatProvider>
          </AppointmentReportProvider>
        </UserProvider>
      </BrowserRouter>
    </AbilityContext.Provider>
  );
}
