import clsx from 'clsx';

function ArrowUpIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-7 h-7', className)}
      fill="none"
      viewBox="0 0 27 27"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.498 24V6.625M5.625 14.5L13.5 6.625l7.875 7.875"
        stroke="#fff"
        strokeWidth={2.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ArrowUpIcon;
