import clsx from 'clsx';

import styles from './PopupButton.module.scss';

const PopupButton = ({className, children, onClick}) => (
  <div className={clsx(styles.btn, className)} onClick={onClick}>
    {children}
  </div>
);

export default PopupButton;
