import * as React from 'react';
import clsx from 'clsx';

const PencilIcon = ({className, ...props}) => (
  <svg
    className={clsx('w-5 h-5', className)}
    fill="none"
    viewBox="0 0 19 19"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.337 2.354a2.34 2.34 0 1 1 3.31 3.31L6.165 16.148 1.75 17.25l1.104-4.414L13.337 2.354Z"
      stroke="#585550"
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PencilIcon;
