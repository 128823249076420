import * as React from 'react';
import clsx from 'clsx';

function SurveyArrowIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-36 h-32', className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.627 26.936c-.577 5.945-.115 11.394 1.162 16.3a40.058 40.058 0 006.062 13.1c2.688 3.818 5.968 7.06 9.616 9.707a45.077 45.077 0 0011.834 6.13 44.032 44.032 0 007.498 1.864c2.548.399 5.113.526 7.668.38 2.547-.157 5.069-.61 7.51-1.424 2.44-.812 4.8-1.978 7.025-3.551 1.365-.966 2.955-2.413 4.47-4.171 1.512-1.757 2.952-3.83 4-6.046 1.049-2.214 1.714-4.574 1.686-6.901-.03-2.328-.748-4.629-2.473-6.735-1.244-1.524-2.854-2.45-4.55-2.613-1.655-.196-3.402.215-5.113.974-1.712.756-3.388 1.872-4.896 3.092a31.56 31.56 0 00-3.883 3.761 44.04 44.04 0 00-4.62 6.487 51.822 51.822 0 00-3.643 7.598 53.867 53.867 0 00-2.455 8.366 50.917 50.917 0 00-1.049 8.767 43.84 43.84 0 00.738 9.625 36.448 36.448 0 002.722 8.52c7.697 16.55 24.286 19.135 37.678 19.295 6.627.075 13.25-.069 19.869-.262a171.008 171.008 0 0020.348-1.792"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M8.038 39.744c7.633-6.753 8.427-18.566 14.566-26.772 2.614 8.211 8.49 24.573 14.464 29.92"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SurveyArrowIcon;
