import loadable from "@loadable/component";
import { loadableRootParams } from "@components/Skeleton/loadableParams";

const Principal = {
  Organization: loadable(() => import("./Organization"), loadableRootParams),
  Users: loadable(() => import("./Users/UsersTable"), loadableRootParams),
  Statistics: loadable(
    () => import("../../components/Statistics/Statistics"),
    loadableRootParams
  ),
  ChangeUser: loadable(() => import("./Users/ChangeUser"), loadableRootParams),
  ProfileUser: loadable(
    () => import("./Users/ProfileUser"),
    loadableRootParams
  ),
  Config: loadable(
    () => import("@components/SurveyAndCheckIns"),
    loadableRootParams
  ),
  ConfigCreate: loadable(
    () => import("@components/SurveyAndCheckIns/Create/CreateConfig"),
    loadableRootParams
  ),
  UsersAccountSelection: loadable(
    () => import("@components/UsersAccountSelection/UsersAccountSelection"),
    loadableRootParams
  ),
};

export default Principal;
