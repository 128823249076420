import { Accordion, AccordionTab } from "primereact/accordion";
import styles from "./AccordionCustom.module.scss";
import clsx from "clsx";
const AccordionCustom = ({
  children,
  multiple = false,
  activeIndex,
  onTabChange = () => {},
  width = "57.313rem",
}) => {
  return (
    <div className={clsx(styles.topicAccordion)}>
      <Accordion
        multiple={multiple}
        onTabChange={onTabChange}
        activeIndex={activeIndex}
        style={{width: width}}
      >
        {children}
      </Accordion>
    </div>
  );
};
export default AccordionCustom;