import broadcastMessagesInstance from "@services/api/broadcastMessage.graphql";
import styles from "./BroadcastMessagesModal.module.scss";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { CustomModal } from "@components/Modals";
import clsx from "clsx";
import { Divider } from "@components/Static";
import { UserContext } from "@contexts/User";

const BroadcastMessagesModal = () => {
    const [messages, setMessages] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [showMessageLength, setShowMessageLength] = useState(0);
    const { showBroadcastModal } = useContext(UserContext);

    useEffect(() => {
        broadcastMessagesInstance.getBroadcastMessagesByUser().then((res) => {
            res.length > 0 && setModalVisible(true);
            setMessages(res);
            setShowMessageLength(0);
        });
    }, [showBroadcastModal]);

    const closeModal = () => {
        broadcastMessagesInstance
            .createBroadcastMessagesUsers(messages[showMessageLength]?.id)
            .then((res) => {
                if (res.id) {
                    if (messages.length - 1 <= showMessageLength) {
                        setModalVisible(false);
                    } else {
                        setShowMessageLength(showMessageLength + 1);
                    }
                }
            });
    };

    return (
        <CustomModal
            openModal={modalVisible}
            setOpenModal={closeModal}
            className={clsx(styles.modal, "bg-milk", "rounded-xl")}
        >
            <div className="flex flex-col items-center pt-10 grow overflow-y-auto">
                <h3 className="text-center font-bold-poppins text-3xl text-dawn-gray tracking-tight mb-8 px-16">
                    {messages[showMessageLength]?.heading}
                </h3>
                <Divider />
                <div className="overflow-y-auto w-full" style={{ maxHeight: "70vh" }}>
                    <p className="text-dawn-gray px-4 text-opacity-60 leading-7 m-6 whitespace-pre-wrap">
                        {messages[showMessageLength]?.message
                            ?.replace(/\\+n/g, "\n")
                            ?.replace(/\\/g, "")}
                    </p>
                </div>
            </div>
        </CustomModal>
    );
};

export default BroadcastMessagesModal;
