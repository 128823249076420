import * as React from 'react';
import clsx from 'clsx';

const StatisticsIcon = ({className, ...props}) => (
  <svg
    className={clsx('w-7 h-7 fill-current', className)}
    fill="none"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.749 11.137A13.878 13.878 0 0 0 16.86.25a3.185 3.185 0 0 0-2.653.662 3.187 3.187 0 0 0-1.172 2.472v7.684a3.9 3.9 0 0 0 3.896 3.896h7.684c.962 0 1.862-.428 2.472-1.173.61-.745.852-1.711.662-2.654Zm-2.306 1.31c-.206.25-.506.393-.828.393h-7.684c-.976 0-1.77-.794-1.77-1.77V3.385a1.072 1.072 0 0 1 1.28-1.05c4.664.941 8.285 4.561 9.224 9.223.064.313-.017.638-.223.888Zm.37 6.114c-1.687 5.535-6.719 9.252-12.521 9.252C6.065 27.813.188 21.935.188 14.71c0-5.803 3.718-10.835 9.252-12.52a1.062 1.062 0 1 1 .62 2.034C5.426 5.635 2.312 9.848 2.312 14.71c0 6.054 4.926 10.98 10.98 10.98 4.86 0 9.075-3.114 10.486-7.748a1.063 1.063 0 0 1 2.034.62Z"
      fillOpacity={0.8}
    />
  </svg>
);

export default StatisticsIcon;
