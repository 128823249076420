import * as React from 'react';

const AppStoreIcon = ({width = 30, height = 30, ...props}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 256 256"
    {...props}
  >
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a">
        <stop stopColor="#17C9FB" offset="0%" />
        <stop stopColor="#1A74E8" offset="100%" />
      </linearGradient>
    </defs>
    <path
      d="M56.064 0h143.872C230.9 0 256 25.1 256 56.064v143.872C256 230.9 230.9 256 199.936 256H56.064C25.1 256 0 230.9 0 199.936V56.064C0 25.1 25.1 0 56.064 0Z"
      fill="url(#a)"
    />
    <path
      d="m82.042 185.81.024.008-8.753 15.16c-3.195 5.534-10.271 7.43-15.805 4.235-5.533-3.195-7.43-10.271-4.235-15.805l6.448-11.168.619-1.072c1.105-1.588 3.832-4.33 9.287-3.814 0 0 12.837 1.393 13.766 8.065 0 0 .126 2.195-1.351 4.391Zm124.143-38.72h-27.294c-1.859-.125-2.67-.789-2.99-1.175l-.02-.035-29.217-50.606-.038.025-1.752-2.512c-2.872-4.392-7.432 6.84-7.432 6.84-5.445 12.516.773 26.745 2.94 31.046l40.582 70.29c3.194 5.533 10.27 7.43 15.805 4.234 5.533-3.195 7.43-10.271 4.234-15.805l-10.147-17.576c-.197-.426-.539-1.582 1.542-1.587h13.787c6.39 0 11.57-5.18 11.57-11.57 0-6.39-5.18-11.57-11.57-11.57Zm-53.014 15.728s1.457 7.411-4.18 7.411H48.092c-6.39 0-11.57-5.18-11.57-11.57 0-6.39 5.18-11.57 11.57-11.57h25.94c4.188-.242 5.18-2.66 5.18-2.66l.024.012 33.86-58.648-.01-.002c.617-1.133.103-2.204.014-2.373l-11.183-19.369c-3.195-5.533-1.299-12.61 4.235-15.804 5.534-3.195 12.61-1.3 15.805 4.234l5.186 8.983 5.177-8.967c3.195-5.533 10.271-7.43 15.805-4.234 5.534 3.195 7.43 10.27 4.235 15.804l-47.118 81.61c-.206.497-.269 1.277 1.264 1.414h28.164l.006.275s16.278.253 18.495 15.454Z"
      fill="#FFF"
    />
  </svg>
);

export default AppStoreIcon;
