import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

function CheckIcon({className, stroke, ...props}) {
  return (
    <svg
      className={clsx('w-4 h-2.5', className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 11"
      {...props}
    >
      <path
        d="M15 1l-9.333 9L1 5.5"
        stroke={stroke}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CheckIcon.propTypes = {
  stroke: PropTypes.string,
};

CheckIcon.defaultProps = {
  stroke: '#FEB95C',
};

export default CheckIcon;
