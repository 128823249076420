import * as React from 'react';

function VideoCrossedIcon(props) {
  return (
    <svg
      className="w-7 h-7"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.112 20.475c-.342 0-.676-.168-.886-.48A7.215 7.215 0 010 15.973v-5.942C0 6.14 3.06 2.974 6.82 2.974h5.744c1.386 0 2.728.438 3.887 1.269.488.35.61 1.044.271 1.55-.34.506-1.009.632-1.499.281a4.55 4.55 0 00-2.66-.872H6.819c-2.573 0-4.667 2.166-4.667 4.829v5.942c0 .978.292 1.929.843 2.753a1.14 1.14 0 01-.271 1.551c-.187.132-.4.198-.612.198zM28 8.899v8.205c0 1.07-.566 2.047-1.476 2.552-.415.23-.87.345-1.324.345a2.73 2.73 0 01-1.55-.484l-4.321-2.973c-.288 3.619-3.194 6.485-6.765 6.485H6.821c-.296 0-.604-.03-.92-.075l-2.627 2.72a1.05 1.05 0 01-1.522 0 1.142 1.142 0 010-1.577L24.726.327a1.052 1.052 0 011.524 0c.42.435.42 1.14 0 1.576l-6.936 7.175c.016.122.029.244.04.362l4.297-2.955a2.716 2.716 0 012.873-.14A2.911 2.911 0 0128 8.899zm-10.77 7.074v-4.737l-9.245 9.566h4.58c2.572 0 4.666-2.166 4.666-4.829zM25.847 8.9a.654.654 0 00-.34-.589.614.614 0 00-.663.033l-5.458 3.753v1.814l5.457 3.752c.293.2.562.09.664.032a.654.654 0 00.34-.589V8.9z"
        fill="#fff"
      />
    </svg>
  );
}

export default VideoCrossedIcon;
