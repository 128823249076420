import { useMemo, useState, useRef, useEffect, useCallback } from 'react';

export const FETCH_TYPES = {
  QUERY: 'query',
  MUTATION: 'mutation',
};

export const useFetchHook = (fetch, type) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(null);
  const isUnmounted = useRef();

  useEffect(() => {
    (async () => {
      if (type === FETCH_TYPES.QUERY) {
        await sendFetch();
      }
    })();
  }, [type]);

  const sendFetch = useCallback(
    async (...params) => {
      setIsSuccess(false);
      setIsLoading(true);

      try {
        const values = await fetch(...params);
        if (!isUnmounted.current) {
          setData(values);
          setIsSuccess(true);
          setIsLoading(false);
        }
        return {data: values, error: null};
      } catch (e) {
        setError(e);
        setIsLoading(false);
        return {data: null, error: e};
      }
    },
    [fetch],
  );

  return useMemo(() => {
    if (type === FETCH_TYPES.QUERY) {
      return {
        data, isLoading, error, isSuccess,
      };
    }
    return (
      [sendFetch, {
        data, isLoading, error, isSuccess,
      }]
    );
  }, [data, isLoading, sendFetch, error, isSuccess]);
};
