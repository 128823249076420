import { useContext, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { format } from "date-fns";
import { Logo, User, Settings, Logout, SelectArrow } from "@assets/icons";
import { BaseLink } from "@components/Form";
import { PROFILE, SETTINGS, APPOINTMENTS } from "@router/consts";
import { sendNotify } from "@components/GlobalNotify/GlobalNotify";
import { Divider, Popup } from "@components/Static";
import { UserContext } from "@contexts/User";
import { useProfileImageUrl } from "@hooks/profile";
import { useFetchHook } from "@hooks/useFetchHook";
import ProfilePicture from "@components/ProfilePicture";
import NavPopupButton from "@components/Static/Popup/NavPopupButton";
import NavbarList from "@components/Navbar/NavbarList";
import {
  USER_ROLES,
  MEDIA_FONT_SIZE,
  USER_STATUSES,
  CUSTOM_USER_ROLES,
} from "@utils/consts";
import Loader from "@components/Loader";
import AccountGraphqlInstance from "@services/api/account.graphql";
import CalendarGraphqlInstance from "@services/api/calendar.graphql";
import styles from "./Navbar.module.scss";
import distributedUserInstance from "@services/api/distributedUser.graphql";
import { ability, convertAbility } from "ability/ability";
import RolesGraphqlInstance from "@services/api/roles.graphql";
import { useUnansweredEvent } from "@hooks/appointment";

const Navbar = ({ className, navItems }) => {
  const { t } = useTranslation();

  const {
    logout,
    profile,
    updateCurrentProfile,
    htmlFontSize,
    user,
    switchAccountTokens,
  } = useContext(UserContext);
  const [sendFetch] = useFetchHook(updateCurrentProfile);

  const profilePictureUrl = useProfileImageUrl(
    profile.profilePictureName,
    profile.accountId
  );
  const [isDistributedLoading, setIsDistributedLoading] = useState(false);
  const [distributedAccounts, setDistributedAccounts] = useState([]);
  const [isSwitchInProgress, setIsSwitchInProgress] = useState(false);
  const [usersAccounts, setUsersAccounts] = useState(null);
  const [unansweredEvent, fetchData, setDistributedUsers] = useUnansweredEvent({
    forAllAccount: true,
  });
  const [isLoading, setIsLoading] = useState(false);

  const now = Date.now();

  useEffect(()=>{(async () => {
    if (!profile) {
      return;
    }
    setIsLoading(true);
    const UsersResponse = await AccountGraphqlInstance.getAccounts();
    const activeAccounts = UsersResponse.filter((a)=> 
    {
      return (now >= Number(a.account.dtActivation) && now < Number(a.account.dtDeactivation))
    })
    setUsersAccounts(activeAccounts);
    setIsLoading(false);
  })()}, [profile]);

  const handleUserOpen = async () => {
    const UsersResponse = await AccountGraphqlInstance.getAccounts();
    const activeAccounts = UsersResponse.filter((a)=> 
    {
      return (now >= Number(a.account.dtActivation) && now < Number(a.account.dtDeactivation))
    })
    setUsersAccounts(activeAccounts);
    fetchData();
  };

  const isAllbryCounsellor = user.roles.some(
    (role) => role.name === USER_ROLES.ALLBRY_COUNSELLOR_ROLE
  );
  const isCounsellor = user.roles.some(
    (role) => role.name === USER_ROLES.COUNSELLOR_ROLE
  );

  useEffect(()=>{(async () => {
    if (!isAllbryCounsellor) {
      return;
    }
    setIsDistributedLoading(true);
    const distributedUsersResponse =
      await distributedUserInstance.getAllAuthDistributedUserByUserId();
    const distributedUsers = distributedUsersResponse.filter(
      (distributedUser) => distributedUser.status === USER_STATUSES.ACTIVE
    );
    let distributedUserIds = [];
    await Promise.all(
      distributedUsers.map((allAccounts) => {
        allAccounts.distributedUser.accounts.map((account) => {
          if (
            now >= Number(account.dtActivation) &&
            now < Number(account.dtDeactivation)
          ) {
            setDistributedAccounts((prev) => {
              return [...prev, allAccounts];
            });
            distributedUserIds.push(allAccounts.distributedUserId);
          }
        });
      })
    );
    setDistributedUsers(distributedUserIds);
    setIsDistributedLoading(false);
    })()}, [isAllbryCounsellor]);

  const handleDistributedOpen = async () => {
    const distributedUsersResponse =
      await distributedUserInstance.getAllAuthDistributedUserByUserId();
    const distributedUsers = distributedUsersResponse.filter(
      (distributedUser) => distributedUser.status === USER_STATUSES.ACTIVE
    );

    let accounts = [];
    await distributedUsers.filter((allAccounts) => {
      allAccounts.distributedUser.accounts.filter((account) => {
        if (
          now >= Number(account.dtActivation) &&
          now < Number(account.dtDeactivation)
        ) {
          accounts.push(allAccounts);
        }
      });
    });
    setDistributedAccounts(accounts);
    fetchData();
  };

  if (!profile) {
    return (
      <div className="h-screen w-36 flex justify-center items-center">
        <Loader height={60} width={60} />
      </div>
    );
  }

  const PopupTrigger = (__open) => (
    <div
      className={clsx("rounded-full w-18 h-17 cursor-pointer bg-silver-light")}
    >
      <ProfilePicture
        profilePictureUrl={profilePictureUrl}
        profile={profile}
        profilePictureStyle={{ height: 17, className: "w-18 h-17" }}
        isShowOnline
        alt="profile"
      />
    </div>
  );

  const SwitchAccountTrigger = (__open) => (
    <div
      className={clsx(
        "flex rounded-md items-center relative justify-center mx-4 py-4 cursor-pointer mb-12",
        __open && "bg-opacity-70 bg-pampas"
      )}
    >
      <Logo />
      <div className="absolute right-1 my-auto">
        <SelectArrow />
      </div>
    </div>
  );

  const onLogout = () => {
    logout();
  };

  const onSwitchAccount = async (id) => {
    if (id === user?.accountId) {
      return;
    }

    try {
      setIsSwitchInProgress(true);
      await switchAccountTokens(id);
      try {
        RolesGraphqlInstance.getRoleFeaturesByCurrentUser().then((data) => {
          if (data) {
            const rules = convertAbility(data || []);
            ability.update(rules);
          }
          setTimeout(() => {
            window.location.replace(
              ability.can(
                CUSTOM_USER_ROLES[USER_ROLES.COUNSELLOR_ROLE].APPOINTMENTS,
                USER_ROLES.COUNSELLOR_ROLE
              ) ||
                ability.can(
                  CUSTOM_USER_ROLES[USER_ROLES.STUDENT_ROLE].APPOINTMENTS,
                  USER_ROLES.STUDENT_ROLE
                )
                ? APPOINTMENTS.toString()
                : SETTINGS
            );
          });
        });
      } catch (err) {
        window.location.replace(
          ability.can(
            CUSTOM_USER_ROLES[USER_ROLES.COUNSELLOR_ROLE].APPOINTMENTS,
            USER_ROLES.COUNSELLOR_ROLE
          ) ||
            ability.can(
              CUSTOM_USER_ROLES[USER_ROLES.STUDENT_ROLE].APPOINTMENTS,
              USER_ROLES.STUDENT_ROLE
            )
            ? APPOINTMENTS.toString()
            : SETTINGS
        );
      }
    } catch (e) {
      setIsSwitchInProgress(false);
      sendNotify(t("common.switchAccountError"), "error");
    }
  };

  const handleOnline = () => {
    if (!isLoading) {
      const updatedProfile = {
        ...profile,
        birthday: format(new Date(profile.birthday), "yyyy-MM-dd"),
        isOnline: !profile.isOnline,
      };
      sendFetch(updatedProfile);
    }
  };

  const UserIcon = () => <User className="fill-current text-dawn-gray" />;
  const AllbryIcon = () => <Logo size="h-10 w-10" />;

  const calculateMessageCounts = (messages, accountId,isGlobal) => {
    return messages.reduce(
      (counts, msg) => {
        if(isGlobal){
          counts.sendAndReadMessageCount += Number(msg.sendMessageCount || 0) + Number(msg.readMessageCount || 0);
          counts.allMessageCount += Number(msg.globalChatRoom?.messageCount || 0);
        }else{
          if (msg.accountId === accountId) {
            counts.sendAndReadMessageCount += Number(msg.sendMessageCount || 0) + Number(msg.readMessageCount || 0);
            counts.allMessageCount += Number(msg.chatRoom?.messageCount || 0);
          }
        }
        return counts;
      },
      { sendAndReadMessageCount: 0, allMessageCount: 0 }
    );
  };
  
  const calculateUnreadMessageCount = (allMessageCount, sendAndReadMessageCount, accountId) => {
    return allMessageCount - sendAndReadMessageCount + (unansweredEvent || []).filter((e) => e.accountId === accountId).length;
  };
  
  const renderAllbryAccounts = useMemo(() => {
    if (isDistributedLoading || !distributedAccounts.length || isSwitchInProgress) {
      return (
        <div className="h-20 flex items-center">
          <Loader height={40} width={40} />
        </div>
      );
    }
  
    return distributedAccounts.map((account) => {
      const { sendAndReadMessageCount, allMessageCount } = calculateMessageCounts(account.distributedUser.chatChatParticipants, account.distributedAccountId,false);
      const { sendAndReadMessageCount: sendAndReadMessageCountGlobal, allMessageCount: allMessageCountGlobal } = calculateMessageCounts(account.distributedUser.chatChatParticipantGlobals, account.distributedAccountId,true);
  
      const unreadMessageCount = calculateUnreadMessageCount(allMessageCount, sendAndReadMessageCount, account.distributedAccountId);
      const unreadMessageCountGlobal = calculateUnreadMessageCount(allMessageCountGlobal, sendAndReadMessageCountGlobal, account.distributedAccountId);
  
      const totalUnreadMessageCount = unreadMessageCount + unreadMessageCountGlobal;
  
      return (
        <NavPopupButton
          key={account.distributedAccountId}
          Icon={AllbryIcon}
          text={account.distributedUser.accounts.map((n) => n.name)}
          className={account.distributedAccountId === user.accountId && "bg-pampas"}
          unreadMessage={totalUnreadMessageCount > 0 ? totalUnreadMessageCount : ""}
          textClassName="max-w-30"
          onClick={() => onSwitchAccount(account.distributedAccountId)}
          isSelected={account.distributedAccountId === user.accountId}
        />
      );
    });
  }, [isDistributedLoading, distributedAccounts, user, isSwitchInProgress, unansweredEvent]);

  const renderUsersAccounts = useMemo(() => {
    if (!usersAccounts || !usersAccounts.length || isSwitchInProgress) {
      return (
        <div className="h-20 flex items-center">
          <Loader height={40} width={40} />
        </div>
      );
    }
  
    return usersAccounts.map((usersAccount) => {
      const { sendAndReadMessageCount, allMessageCount } = calculateMessageCounts(usersAccount.user.chatChatParticipants, usersAccount.account.id, false);
      const { sendAndReadMessageCount: sendAndReadMessageCountGlobal, allMessageCount: allMessageCountGlobal } = calculateMessageCounts(usersAccount.user.chatChatParticipantGlobals, usersAccount.account.id, true);
  
      const unreadMessage = calculateUnreadMessageCount(allMessageCount, sendAndReadMessageCount, usersAccount.account.id);
      const unreadMessageGlobal = calculateUnreadMessageCount(allMessageCountGlobal, sendAndReadMessageCountGlobal, usersAccount.account.id);
  
      const unreadMessageCount = unreadMessage + unreadMessageGlobal;
  
      return (
        <NavPopupButton
          key={usersAccount.account.id}
          Icon={AllbryIcon}
          text={usersAccount.account.name}
          unreadMessage={unreadMessageCount > 0 ? unreadMessageCount : ""}
          className={usersAccount.account.id === user.accountId && "bg-pampas"}
          textClassName="max-w-30"
          onClick={() => handleAccountSwitch(usersAccount.account.id)}
          isSelected={usersAccount.account.id === user.accountId}
        />
      );
    });
  }, [usersAccounts, user, isSwitchInProgress, unansweredEvent]);

  const getRedirectUrl = () => {
    return ability.can(CUSTOM_USER_ROLES[USER_ROLES.COUNSELLOR_ROLE].APPOINTMENTS, USER_ROLES.COUNSELLOR_ROLE) ||
      ability.can(CUSTOM_USER_ROLES[USER_ROLES.STUDENT_ROLE].APPOINTMENTS, USER_ROLES.STUDENT_ROLE)
      ? APPOINTMENTS.toString()
      : SETTINGS;
  };
  
  const handleAccountSwitch = (accountId) => {
    AccountGraphqlInstance.updateAuthUserSession(accountId).then(() => {
      // Set roles and permissions
      try {
        RolesGraphqlInstance.getRoleFeaturesByCurrentUser().then((data) => {
          if (data) {
            const rules = convertAbility(data || []);
            ability.update(rules);
          }
          setTimeout(() => {
            window.location.replace(
              getRedirectUrl()
            );
          });
        });
      } catch (err) {
        window.location.replace(
          getRedirectUrl()
        );
      }
    });
  };

  return (
    <div className={clsx(styles.nav, className)}>
      <div
        className={clsx(
          "w-full flex flex-col",
          isAllbryCounsellor ? "pt-4" : "pt-8"
        )}
      >
        {distributedAccounts.length > 1 ? (
          <Popup
            variant="accounts"
            position={["right"]}
            trigger={SwitchAccountTrigger}
            onOpen={handleDistributedOpen}
          >
            <>{renderAllbryAccounts}</>
          </Popup>
        ) : usersAccounts && usersAccounts.length > 1 ? (
          <Popup
            variant="accounts"
            position={["right"]}
            trigger={SwitchAccountTrigger}
            onOpen={handleUserOpen}
          >
            <>{renderUsersAccounts}</>
          </Popup>
        ) : (
          <BaseLink
            to={APPOINTMENTS}
            className="flex items-center justify-center w-full cursor-pointer mb-16"
          >
            <Logo className="mb-4" />
          </BaseLink>
        )}
        <NavbarList navItems={navItems} />
      </div>
      <div className="pb-16">
        <Popup
          variant="navbar"
          position={["right"]}
          trigger={PopupTrigger}
          offsetY={
            (htmlFontSize / MEDIA_FONT_SIZE.BASE) * (isCounsellor ? -108 : -56)
          }
        >
          <>
            {isCounsellor && (
              <>
                <div
                  onClick={handleOnline}
                  className="flex w-full my-2 py-5 pl-5 rounded-md text-lg text-dawn-gray tracking-tight hover:bg-pampas hover:bg-opacity-50 cursor-pointer"
                >
                  {t("navbar.switch")}
                  &nbsp;
                  <span
                    className={clsx(
                      !profile.isOnline ? "text-jungle-green" : "text-stone"
                    )}
                  >
                    {profile.isOnline
                      ? t("navbar.toOffline")
                      : t("navbar.toOnline")}
                  </span>
                </div>
                <Divider className="mb-5" />
              </>
            )}
            <NavPopupButton
              Icon={UserIcon}
              path={`${PROFILE}/${profile.id}`}
              label="navbar.profile"
            />
            <NavPopupButton
              Icon={Settings}
              path={SETTINGS}
              label="navbar.settings.title"
            />
            <NavPopupButton
              Icon={Logout}
              label="navbar.logOut"
              textClassName="text-pepper"
              onClick={onLogout}
            />
          </>
        </Popup>
      </div>
    </div>
  );
};

export default Navbar;
