import * as React from 'react';
import clsx from 'clsx';

function ChatRoomsIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-7 h-7', className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.707 11.715a8.389 8.389 0 01-.88 2.504c.07.31.106.627.106.946a4.54 4.54 0 01-.701 2.402c-.198.32-.251.708-.271.982-.023.31-.013.652.01.987.033.447.095.92.161 1.349a24.111 24.111 0 00-1.536-.415 9.543 9.543 0 00-1.077-.191c-.297-.032-.698-.053-1.045.059a9 9 0 01-2.766.427c-1.323 0-2.531-.268-3.566-.727-.075.002-.15.002-.225.002-1.187 0-2.333-.142-3.415-.406 1.681 1.811 4.346 2.931 7.206 2.931a10.797 10.797 0 003.308-.51h.012c.031-.002.123-.005.299.014.236.025.532.08.867.156.667.153 1.409.375 1.969.554 1.025.328 2.029-.566 1.84-1.614a17.878 17.878 0 01-.236-1.76 5.231 5.231 0 01-.011-.724c.009-.117.022-.177.027-.197v-.004c.604-.99.95-2.116.95-3.315a6.33 6.33 0 00-1.026-3.45zm-4.686 10.337a.153.153 0 00.006-.002l-.002.001-.004.002z"
        fill="#fff"
      />
      <path
        d="M21.125 10.292c0 3.889-4.122 7.041-9.208 7.041-1.352 0-2.635-.222-3.792-.622-.756-.262-3.388.685-4.889 1.272-.408.16-.829-.243-.691-.658.47-1.415 1.173-3.768.87-4.325a5.623 5.623 0 01-.706-2.708c0-3.89 4.122-7.042 9.208-7.042s9.208 3.153 9.208 7.042z"
        stroke="#fff"
        strokeWidth={1.8}
      />
    </svg>
  );
}

export default ChatRoomsIcon;
