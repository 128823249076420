import React from 'react';
import PropTypes from 'prop-types';
import {Roller} from 'react-css-spinners';
import clsx from 'clsx';
import styles from './Switch.module.scss';

const Switch = ({
  isChecked, isDisabled, onToggle, isLoading, className,
}) => (
  <div className="flex justify-between items-center">
    <div
      className={
        clsx(
          styles.toggle,
          // eslint-disable-next-line no-nested-ternary
          isDisabled || isLoading ? styles.disabled : isChecked ? styles.active : '',
          className,
        )
      }
      onClick={() => onToggle(!isChecked)}
    >
      <div
        className={
          clsx(
            `${styles.check}`,
            // eslint-disable-next-line no-nested-ternary
            isDisabled || isLoading ? styles.disabled : isChecked ? styles.transformation : '',
          )
        }
      />
    </div>
    <Roller size={30} color="#B2B2B2" className={clsx(styles.spinner, isLoading ? '' : 'opacity-0')} />
  </div>
);

Switch.propTypes = {
  className: PropTypes.string,
  isChecked: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onToggle: PropTypes.func,
};

Switch.defaultProps = {
  className: '',
  isChecked: false,
  isLoading: false,
  isDisabled: false,
  onToggle: () => {},
};

export default Switch;
