import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import Loader from '@components/Loader';
import styles from './Button.module.scss';

const TypeBtn = ({option, ...props}) => {
  if (option === 'link') return <Link {...props} />;
  if (option === 'div') return <div {...props} />;

  return <button type="button" {...props} />;
};

const Button = ({
  variant = 'default', className, isDisabled, isLoading, children, ...props
}) => (
  <TypeBtn
    className={
      clsx(
        className,
        isDisabled ? `${styles.disabled}` : styles[variant],
      )
    }
    {...props}
  >
    {isLoading && <Loader height={20} width={20} stroke="var(--colors-white)" className="absolute" />}
    <div className={clsx('flex justify-center items-center', isLoading ? 'opacity-0' : '')}>
      {children}
    </div>
  </TypeBtn>
);

Button.propTypes = {
  variant: PropTypes.oneOf(['default', 'empty', 'disabled', 'custom', 'edit', 'empty-borderless', 'secondary', 'astext', 'modal', 'withoutBg']),
  option: PropTypes.oneOf(['link', 'button', 'div']),
  isDisabled: PropTypes.bool,
};

Button.defaultProps = {
  variant: 'default',
  option: 'button',
  isDisabled: false,
};

export default Button;
