import config from '@config/config';
import ApiService from './api';
import LocalStorageService from '../localStorage';

class AuthApi extends ApiService {
  constructor() {
    if (AuthApi.instance) {
      return AuthApi.instance;
    }

    super(config.authApiUrl);
    AuthApi.instance = this;
  }

  async login(email, password) {
    const response = await this.http.post('/login', {email, password});
    
    if (response.data.redirectUrl) {
      const form = document.createElement('form');

      form.method = 'POST';
      form.action = `${config.authApiUrl}${response.data.redirectUrl}`;
      document.body.appendChild(form);
      form.submit();
    }

    return response.data;
  }

  async isSSO(email, isRegistration) {
    const response = await this.http.post('/sso/check', {email, registration: isRegistration});

    if (response.data.redirectUrl) {
      const form = document.createElement('form');

      form.method = 'POST';
      form.action = `${config.authApiUrl}${response.data.redirectUrl}`;
      document.body.appendChild(form);
      form.submit();

      return true;
    }

    return false;
  }

  async emailRegistration(email, password) {
    const response = await this.http.post('/register', {email, password});
    return response.data;
  }

  async forgotPassword(email) {
    const response = await this.http.post('/forgotPassword', {email});
    return response.data;
  }

  async resetPassword(newPassword, token) {
    const response = await this.http.post('/resetPassword', {
      newPassword,
      resetPasswordToken: token,
    });

    return response.data;
  }

  async logout() {
    const response = await this.http.post('/logout');

    return response.data;
  }

  async getCurrentUser() {
    const response = await this.http.get('/session/current');
    return response.data;
  }

  async refreshToken() {
    const refreshToken = LocalStorageService.getRefreshToken();
    if (!refreshToken) {
      LocalStorageService.removeAccessToken();
      LocalStorageService.removeRefreshToken();
      LocalStorageService.removeAppCache();
      return null;
    }

    const response = await this.http.post('/refreshToken', {refreshToken});
    return response.data;
  }

  async switchAccount(accountId) {
    const response = await this.http.post('/switchAccount', {accountId});
    return response.data;
  }
}

const authApiInstance = new AuthApi();
export default authApiInstance;
