/* eslint-disable react/no-array-index-key */
import React, { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useFormik } from "formik";
import * as yup from "yup";

import { Button, Input } from "@components/Form";
import { BoldClose } from "@assets/icons";
import { setFieldForm } from "@utils/helpers";
import { CustomModal } from "@components/Modals";
import { Divider } from "@components/Static";
import Dropdown from "@components/Form/Dropdown";

const FORM_STATE = {
  FILTERS: "filters",
};

const UserFilterModal = ({
  isOpened,
  onSubmit,
  onToggle,
  filterOptions,
  filters,
}) => {
  const { t } = useTranslation();

  const equalityOptions = [
    {
      value: "true",
      label: t("filterModal.is"),
    },
    {
      value: "false",
      label: t("filterModal.isNot"),
    },
  ];

  const validationSchema = yup.object({
    [FORM_STATE.FILTERS]: yup.array().of(
      yup.object().shape({
        key: yup.string().max(50),
        isEqual: yup.boolean(),
        value: yup
          .mixed()
          .when("key", (key, schema) => {
            // Check if filterOptions exists and key is valid
            const option = filterOptions?.find((v) => v.value === key);

            if (option?.isNumeric) {
              // If key is numeric, enforce number validation
              return yup.number().required();
            } else {
              // Otherwise, treat as a string with max length
              return yup.string().trim().max(50);
            }
          }),
      })
    ),
  });


  const initialState = useMemo(
    () => ({ [FORM_STATE.FILTERS]: filters?.length ? filters : [{}] }),
    [filters]
  );

  const { handleSubmit, setFieldValue, values, errors, setErrors, resetForm } =
    useFormik({
      initialValues: initialState,
      validationSchema,
      enableReinitialize: true,
      onSubmit: (val) => handleFormSubmit(val),
    });

  useEffect(() => {
    if (!isOpened) {
      resetForm();
    }
  }, [isOpened]);

  const handleAddCondition = () => {
    setFieldForm(setFieldValue, setErrors, FORM_STATE.FILTERS, [
      ...values[FORM_STATE.FILTERS],
      {},
    ]);
  };

  const handleFormSubmit = (formValues) => {
    const result = formValues[FORM_STATE.FILTERS]
      .filter((v) => !!Object.keys(v).length)
      .map((v) => {
        const optionValue = filterOptions.find((j) => j.value === v.key);
        if (!optionValue) {
          return {};
        }

        let { value } = v;
        if (optionValue.isNumeric) {
          const numericValue = Number(v.value);
          value = Number.isNaN(numericValue) ? 0 : numericValue;
        }

        return {
          key: v.key,
          isEqual: !!v.isEqual,
          value,
        };
      });

    onSubmit(result);
    onToggle(false);
  };

  const handleFilterDelete = (index) => {
    const filter = values[FORM_STATE.FILTERS][index];

    if (!filter) {
      return;
    }

    if (values[FORM_STATE.FILTERS].length !== 1) {
      setFieldForm(
        setFieldValue,
        setErrors,
        FORM_STATE.FILTERS,
        values[FORM_STATE.FILTERS].filter((__v, i) => i !== index)
      );
      return;
    }

    if (!filter.key) {
      return;
    }

    setFieldForm(setFieldValue, setErrors, FORM_STATE.FILTERS, [{}]);
  };

  const keyBasedDropdowns = (filter, index) => {
    const optionValue = filterOptions.find((v) => v.value === filter.key);

    return (
      <div key={`filter-${index}`} className="flex flex-row mt-5">
        <Dropdown
          onChange={({ value }) => {
            setFieldForm(
              setFieldValue,
              setErrors,
              `${FORM_STATE.FILTERS}[${index}][value]`,
              undefined
            );
            setFieldForm(
              setFieldValue,
              setErrors,
              `${FORM_STATE.FILTERS}[${index}][key]`,
              value
            );
          }}
          scrollID={`filter-${index}-key`}
          classSelect="py-5"
          options={filterOptions}
          value={filter.key}
          className="bg-silver-light flex rounded cursor-pointer w-64"
        />
        <Dropdown
          onChange={({ value }) =>
            setFieldForm(
              setFieldValue,
              setErrors,
              `${FORM_STATE.FILTERS}[${index}][isEqual]`,
              value === "true"
            )
          }
          scrollID={`filter-${index}-equality`}
          classSelect="py-5"
          options={equalityOptions}
          value={filter.isEqual ? "true" : "false"}
          className="bg-silver-light flex rounded cursor-pointer w-24 ml-6"
        />
        {optionValue?.options ? (
          <Dropdown
            onChange={({ value }) =>
              setFieldForm(
                setFieldValue,
                setErrors,
                `${FORM_STATE.FILTERS}[${index}][value]`,
                value
              )
            }
            scrollID={`filter-${index}-value`}
            classSelect="py-5"
            options={optionValue.options}
            value={filter.value}
            error={
              errors[FORM_STATE.FILTERS] &&
              errors[FORM_STATE.FILTERS][index]?.value
            }
            className="bg-silver-light flex rounded cursor-pointer flex-1 ml-6"
          />
        ) : (
          <Input
            className="flex flex-1 ml-6"
            innerClassName={clsx(
              "w-full",
              errors[FORM_STATE.FILTERS] &&
              errors[FORM_STATE.FILTERS][index]?.value &&
              "border-light-red"
            )}
            placeholder="filterModal.conditionValue"
            value={filter.value ?? ""}
            onChange={(value) => {
              if (optionValue.isNumeric && !Number.isInteger(Number(value))) {
                return;
              }

              setFieldForm(
                setFieldValue,
                setErrors,
                `${FORM_STATE.FILTERS}[${index}][value]`,
                value
              );
            }}
          />
        )}
        <BoldClose
          onClick={() => handleFilterDelete(index)}
          className="ml-6 cursor-pointer self-center hover:opacity-80"
        />
      </div>
    );
  };

  return (
    <CustomModal
      outsideClickClose
      hideCloseButton
      openModal={isOpened}
      setOpenModal={onToggle}
      className="bg-milk rounded-xl w-130 h-125"
    >
      <form onSubmit={handleSubmit} className="flex flex-col h-full">
        <span className="font-bold-poppins text-3xl text-dawn-gray tracking-tight py-7 px-12 leading-10">
          {t("filterModal.addFilter")}
        </span>
        <Divider />
        <div className="flex flex-col flex-1 pl-12 pr-16 py-9 overflow-y-auto">
          <span className="text-dawn-gray text-lg mb-2">
            {t("filterModal.description")}
          </span>
          {values[FORM_STATE.FILTERS].map((filter, index) => {
            if (!filter?.key) {
              return (
                <div key={`filter-${index}`} className="flex flex-row mt-5">
                  <Dropdown
                    onChange={({ value }) =>
                      setFieldForm(
                        setFieldValue,
                        setErrors,
                        `${FORM_STATE.FILTERS}[${index}][key]`,
                        value
                      )
                    }
                    scrollID={`filter-${index}`}
                    key={`filter-${index}`}
                    placeholder="Choose filter"
                    classSelect="py-5"
                    options={filterOptions}
                    className="bg-silver-light flex rounded cursor-pointer w-64"
                  />
                  {values[FORM_STATE.FILTERS].length > 1 && (
                    <BoldClose
                      width={50}
                      onClick={() => handleFilterDelete(index)}
                      className="ml-6 cursor-pointer self-center hover:opacity-80"
                    />
                  )}
                </div>
              );
            }

            return keyBasedDropdowns(filter, index);
          })}
          <div className="mt-5">
            <span
              onClick={() => {
                if (values[FORM_STATE.FILTERS].length < 40) {
                  handleAddCondition();
                }
              }}
              className={clsx(
                "font-bold text-lg",
                values[FORM_STATE.FILTERS].length < 40
                  ? "text-primary cursor-pointer hover:opacity-80"
                  : "text-silver"
              )}
            >
              {t("filterModal.addCondition")}
            </span>
          </div>
        </div>
        <div className="flex flex-row justify-end shadow-top py-7 px-12">
          <Button
            className="py-4 px-6 mr-4"
            variant="custom"
            onClick={() => onToggle(false)}
          >
            <span className="text-dawn-gray text-base font-extrabold">
              {t("common.cancel")}
            </span>
          </Button>
          <Button type="submit" className="py-4 px-16 rounded-full">
            <span className="text-white text-base font-extrabold">
              {t("common.save")}
            </span>
          </Button>
        </div>
      </form>
    </CustomModal>
  );
};

export default React.memo(UserFilterModal);
