export const appointmentAdditionalSupportOptions = [
  {
    label: "reports.additionalSupport.youthReception",
    value: "youth_reception",
  },
  {
    label: "reports.additionalSupport.socialServices",
    value: "social_services",
  },
  {
    label: "reports.additionalSupport.medicalAndHealthcare",
    value: "medical_and_healthcare",
  },
];
