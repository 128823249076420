export const ADMIN_ROOT = '/';
export const ADMIN_STATISTIC = `${ADMIN_ROOT}statistics`;
export const ADMIN_HOME = `${ADMIN_ROOT}organizations`;
export const ADMIN_SETTINGS = `${ADMIN_ROOT}settings`;
export const ADMIN_SUPER_ACTION = `${ADMIN_ROOT}superActions`;
export const ADMIN_SIGN_IN = '/sign-in';
export const REDIRECT = '*';

export const ADD_ORGANIZATION = `${ADMIN_HOME}/add`;
export const EDIT_ORGANIZATION = `${ADMIN_HOME}/:id/edit`;

export const ADMIN_COUNSELLORS = `${ADMIN_ROOT}counsellors`;
export const ADMIN_DATA_MIGRATION= `${ADMIN_ROOT}dataMigration`;
export const ADMIN_CONFIG_DATA = `${ADMIN_ROOT}organizationsConfig`;
export const ADMIN_VIEW_ORGANIZATION_CONFIG = `${ADMIN_CONFIG_DATA}/:id`;
export const ADMIN_CONFIG_FORM = `${ADMIN_ROOT}configForm`;
export const ADMIN_EDIT_CONFIG_FORM = `${ADMIN_ROOT}configForm/:id/edit`;
export const ADMIN_NOTIFICATIONS = `${ADMIN_ROOT}notifications`;
export const ADMIN_ADD_COUNSELLORS = `${ADMIN_COUNSELLORS}/add`;
export const ADMIN_VIEW_COUNSELLOR = `${ADMIN_COUNSELLORS}/:id`;
export const ADMIN_EDIT_COUNSELLORS = `${ADMIN_COUNSELLORS}/:id/edit`;

export const ADMIN_CHECK_INS_AND_SURVEYS = `${ADMIN_ROOT}checkInsAndSurveys/:template`;
export const ADMIN_CREATE_SURVEY_TEMPLATE = `${ADMIN_ROOT}checkInsAndSurveys/:template/create-template`;
export const ADMIN_EDIT_SURVEY_TEMPLATE = `${ADMIN_ROOT}checkInsAndSurveys/:template/:id/edit-template`;
export const ADMIN_CREATE_CONFIG_TEMPLATE = `${ADMIN_ROOT}checkInsAndSurveys/:template/create-config-template`;
export const ADMIN_EDIT_CONFIG_TEMPLATE = `${ADMIN_ROOT}checkInsAndSurveys/:template/:id/edit-config`;
export const ADMIN_SURVEYS_TEMPLATE_STATISTICS = `${ADMIN_CHECK_INS_AND_SURVEYS}/Statistics/:id`;

export const ADMIN_BROADCAST_MESSAGES = `${ADMIN_ROOT}broadcastMessages`;
export const ADMIN_MESSAGES = `${ADMIN_ROOT}messages`;