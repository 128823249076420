import clsx from 'clsx';
import PropTypes from 'prop-types';

const Divider = ({className, isVertical = false}) => (
  <div className={clsx('bg-silver-md-light', isVertical ? 'h-full w-px' : 'w-full h-px', className)} />
);

Divider.propTypes = {
  isVertical: PropTypes.bool,
};

Divider.defaultProps = {
  isVertical: false,
};

export default Divider;
