import * as React from 'react';
import clsx from 'clsx';

const ManyUsersIcon = ({className, color="none",...props}) => (
  <svg
    className={clsx('w-9 h-9', className)}
    fill={color}
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 18.064c3.71 0 6.73-3.02 6.73-6.73 0-3.71-3.02-6.729-6.73-6.729a6.737 6.737 0 0 0-6.73 6.73c0 3.71 3.02 6.729 6.73 6.729ZM17 6.73a4.61 4.61 0 0 1 4.604 4.605A4.61 4.61 0 0 1 17 15.939a4.61 4.61 0 0 1-4.604-4.604A4.61 4.61 0 0 1 17 6.73Zm9.563 17.921v1.227c0 1.172-.43 2.295-1.207 3.164-.209.235-.5.354-.792.354a1.062 1.062 0 0 1-.792-1.77c.427-.48.664-1.1.664-1.748v-1.227c0-1.77-1.173-3.299-2.852-3.72a1.083 1.083 0 0 0-.794.1 7.811 7.811 0 0 1-7.575.006 1.093 1.093 0 0 0-.802-.107c-1.68.422-2.853 1.952-2.853 3.721v1.227c0 .648.236 1.268.664 1.747a1.062 1.062 0 1 1-1.584 1.417 4.737 4.737 0 0 1-1.207-3.164v-1.227c0-2.744 1.833-5.121 4.459-5.782a3.213 3.213 0 0 1 2.363.317 5.68 5.68 0 0 0 5.49-.006 3.194 3.194 0 0 1 2.355-.31c2.63.66 4.462 3.037 4.462 5.781ZM24.498 5.556a1.063 1.063 0 0 1 1.355-.647 5.32 5.32 0 0 1 3.542 5.009c0 1.51-.646 2.952-1.771 3.96a1.062 1.062 0 1 1-1.417-1.584 3.187 3.187 0 0 0-1.062-5.382 1.062 1.062 0 0 1-.648-1.356Zm6.314 15.459v1.023a1.063 1.063 0 0 1-2.125 0v-1.023c0-1.393-.923-2.597-2.243-2.928a1.063 1.063 0 0 1 .517-2.062c2.268.57 3.85 2.621 3.85 4.99ZM6.375 14.507a5.321 5.321 0 0 1-1.77-3.96 5.32 5.32 0 0 1 3.54-5.01 1.062 1.062 0 1 1 .708 2.003 3.193 3.193 0 0 0-2.124 3.007c0 .905.387 1.77 1.063 2.376a1.062 1.062 0 1 1-1.417 1.584Zm1.18 4.208c-1.32.332-2.242 1.536-2.242 2.929v1.024a1.063 1.063 0 0 1-2.125 0v-1.023c0-2.369 1.583-4.42 3.85-4.99a1.062 1.062 0 1 1 .517 2.06Z"
    />
  </svg>
);

export default ManyUsersIcon;
