import {BrowserRouter} from 'react-router-dom';
import AdminView from '@admin/App';
import AdminProvider from '@admin/contexts/AdminContext';

const AdminApp = () => (
  <AdminProvider>
    <BrowserRouter>
      <AdminView />
    </BrowserRouter>
  </AdminProvider>
);

export default AdminApp;
