import loadable from '@loadable/component';
import {loadableRootParams} from '@components/Skeleton/loadableParams';
import MobileView from './MobileView';

const Root = {
  Profile: loadable(() => import('./Profile'), loadableRootParams),
  EditProfile: loadable(() => import('./EditProfile'), loadableRootParams),
  Settings: loadable(() => import('./Settings'), loadableRootParams),
  VideoRoom: loadable(() => import('./VideoRoom'), loadableRootParams),
  MobileView,
};

export default Root;
