import * as React from 'react';
import clsx from 'clsx';

function PhoneIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-9 h-5', className)}
      fill="none"
      viewBox="0 0 35 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.914 16.805c.16.114.308.207.444.285.813.462 1.852.398 2.645-.166l5.163-3.652a2.515 2.515 0 001.057-2.348 5.451 5.451 0 00-.055-.371c-.033-.178.013-.42.237-.507a13.83 13.83 0 0110.177.05c.227.089.268.324.233.498a5.7 5.7 0 00-.074.49 2.544 2.544 0 001.021 2.322l4.998 3.673a2.5 2.5 0 002.603.238c.197-.098.413-.223.648-.385 1.525-1.052 2.487-2.73 2.64-4.612a6.517 6.517 0 00-1.999-5.257c-8.455-8.087-21.758-8.152-30.29-.145l-.127.123A6.511 6.511 0 00.322 12.13c.13 1.892 1.075 3.597 2.592 4.675zm-.436-4.825a4.445 4.445 0 011.363-3.49c7.694-7.22 19.693-7.161 27.319.132a4.371 4.371 0 011.338 3.523c-.082 1-.486 1.884-1.16 2.55-.167.167-.352.32-.551.458-.14.096-.267.171-.384.229a.352.352 0 01-.364-.043l-4.999-3.673a.375.375 0 01-.153-.343 3.59 3.59 0 01.048-.315c.246-1.255-.392-2.455-1.55-2.919a15.985 15.985 0 00-11.76-.057c-1.16.45-1.812 1.646-1.581 2.912.013.074.024.15.034.23a.373.373 0 01-.16.335l-5.162 3.653c-.11.077-.245.097-.33.05a2.923 2.923 0 01-.262-.167c-.986-.701-1.6-1.818-1.686-3.065z"
        fill="#fff"
      />
    </svg>
  );
}

export default PhoneIcon;
