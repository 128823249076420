import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

import {PopupButton} from '@components/Static';
import {Checkbox} from '@assets/icons';

const NavPopupButton = ({
  Icon, label, path, onClick, textClassName, text, isSelected, className, unreadMessage, labelColor=''
}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const onRedirect = () => {
    if (path) {
      navigate(path);
    }

    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <PopupButton className={clsx('flex w-full flex-row justify-between my-1', className)} onClick={onRedirect}>
      <div className="flex flex-row items-center">
        {Icon && (
        <div className="w-6 flex justify-center items-center">
          <Icon />
        </div>
        )}
        <div style={{color: labelColor}} className={clsx('text-lg text-dawn-gray relative', Icon ? 'ml-5' : '', textClassName)}>{text || t(label)}
        {unreadMessage
          ? 
          <>
            <div className="absolute inline-flex w-5 h-5 text-xs font-bold -right-6 bg-primary rounded-full -top-2 items-center justify-center">
              <span className = "text-white font-light-poppins font-black">
                {unreadMessage}
              </span> 
            </div>
          </>
          : ''
        }
        </div>
      </div>
      {isSelected && <Checkbox size="h-6 w-6" />}
    </PopupButton>
  );
};

export default NavPopupButton;
