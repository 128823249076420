import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import config from '@config/config';
import { setContext } from '@apollo/client/link/context';
import LocalStorageService from '../localStorage';

class GraphQlService {
  constructor() {
    const httpLink = createHttpLink({
      uri: config.graphQlUrl,
    });

    const authLink = setContext((_, { headers }) => {
      const token = LocalStorageService.getAccessToken();
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    this.client = new ApolloClient({
      cache: new InMemoryCache(),
      link: authLink.concat(httpLink),
      defaultOptions:{
        query:{
          fetchPolicy: 'network-only'
        }
      }
    });
  }
}

export default GraphQlService;
