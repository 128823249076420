import clsx from 'clsx';

function ArrowRightIcon({className, color, ...props}) {
  return (
    <svg className={clsx('w-3 h-5', className)} viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.9502 16.9201L8.4702 10.4001C9.2402 9.63008 9.2402 8.37008 8.4702 7.60008L1.9502 1.08008" stroke={color || '#585550'} strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default ArrowRightIcon;
