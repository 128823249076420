import {
  differenceInDays,
  differenceInWeeks,
  differenceInHours,
  differenceInMinutes,
  isAfter,
  isSameDay,
} from 'date-fns';

export const APPOINTMENT_STATUSES = {
  TODAY: 'today',
  FUTURE: 'future',
  UNANSWERED: 'unanswered',
  ACTIVE: 'active',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
};

export const APPOINTMENT_STATUS_DATA = {
  [APPOINTMENT_STATUSES.TODAY]: '--colors-green',
  [APPOINTMENT_STATUSES.FUTURE]: '--colors-primary',
  [APPOINTMENT_STATUSES.COMPLETED]: '--colors-gray-extra-light',
  [APPOINTMENT_STATUSES.CANCELLED]: '--colors-red',
};

const tileBackgroundColors = {
  [APPOINTMENT_STATUSES.TODAY]: '#f0f8f2',
  [APPOINTMENT_STATUSES.FUTURE]: '#fff7ed',
  [APPOINTMENT_STATUSES.COMPLETED]: '#f9f8f5',
  [APPOINTMENT_STATUSES.CANCELLED]: '#fef1ee',
};

const setStatusBackgroundColor = (color) => `rgba(var(${color}-rgb), 0.2)`;
const setTileBackGroundColor = (color) => tileBackgroundColors[color];
const setTileBackGroundColorHover = (color) => `rgba(var(${color}-rgb), 1)`;
const setColor = (color) => `var(${color})`;

export const getStatus = (dtStart, status, dtEnd) => {
  let color; let text; let backgroundColor; let clientStatus; let tileBackGroundColor; let tileBackGroundColorHover;
  if (status === APPOINTMENT_STATUSES.CANCELLED) {
    color = setColor(APPOINTMENT_STATUS_DATA.cancelled);
    backgroundColor = setStatusBackgroundColor(APPOINTMENT_STATUS_DATA.cancelled);
    tileBackGroundColor = setTileBackGroundColor(APPOINTMENT_STATUSES.CANCELLED);
    tileBackGroundColorHover = setTileBackGroundColorHover(APPOINTMENT_STATUS_DATA.cancelled);
    text = `appointments.${status}`;
    clientStatus = status;
  } else if (status !== APPOINTMENT_STATUSES.COMPLETED && ((isSameDay(dtStart, Date.now()) && Date.now() < dtStart)
    || (+new Date() > dtStart && +new Date() < dtEnd))) {
    color = setColor(APPOINTMENT_STATUS_DATA.today);
    backgroundColor = setStatusBackgroundColor(APPOINTMENT_STATUS_DATA.today);
    if ((+new Date() > dtStart && +new Date() < dtEnd)) {
      text = 'appointments.now';
    } else {
      const hoursInterval = differenceInHours(dtStart, new Date()) + 1;

      if (hoursInterval > 1) {
        text = {path: 'appointments.hoursInterval', interval: hoursInterval};
      } else {
        const minutesInterval = differenceInMinutes(dtStart, new Date()) + 1;
        text = minutesInterval > 1
          ? {path: 'appointments.minutesInterval', interval: minutesInterval}
          : {path: 'appointments.minuteInterval', interval: minutesInterval};
      }
    }

    tileBackGroundColor = setTileBackGroundColor(APPOINTMENT_STATUSES.TODAY);
    tileBackGroundColorHover = setTileBackGroundColorHover(APPOINTMENT_STATUS_DATA.today);
    clientStatus = APPOINTMENT_STATUSES.TODAY;
  } else if (status !== APPOINTMENT_STATUSES.COMPLETED && isAfter(dtStart, new Date())) {
    const weekInterval = differenceInWeeks(
      dtStart,
      new Date(),
    ) + 1;

    color = setColor(APPOINTMENT_STATUS_DATA.future);
    backgroundColor = setStatusBackgroundColor(APPOINTMENT_STATUS_DATA.future);
    tileBackGroundColor = setTileBackGroundColor(APPOINTMENT_STATUSES.FUTURE);
    tileBackGroundColorHover = setTileBackGroundColorHover(APPOINTMENT_STATUS_DATA.future);

    if (weekInterval > 1) {
      text = {path: 'appointments.weeksInterval', interval: weekInterval};
    } else {
      const daysDiff = differenceInDays(dtStart, new Date()) + 1;
      text = daysDiff > 1 ? {path: 'appointments.daysInterval', interval: daysDiff} : {path: 'appointments.dayInterval', interval: daysDiff};
    }

    clientStatus = APPOINTMENT_STATUSES.FUTURE;
  } else {
    color = setColor(APPOINTMENT_STATUS_DATA.completed);
    backgroundColor = setStatusBackgroundColor(APPOINTMENT_STATUS_DATA.completed);
    tileBackGroundColor = setTileBackGroundColor(APPOINTMENT_STATUSES.COMPLETED);
    tileBackGroundColorHover = setTileBackGroundColorHover(APPOINTMENT_STATUS_DATA.completed);
    text = `appointments.${APPOINTMENT_STATUSES.COMPLETED}`;
    clientStatus = APPOINTMENT_STATUSES.COMPLETED;
  }

  return {
    color, text, backgroundColor, clientStatus, tileBackGroundColor, tileBackGroundColorHover,
  };
};
