import {useTranslation} from 'react-i18next';

import {Button} from '@components/Form';
import {CustomModal} from '@components/Modals';

const FileSizeLimitModal = ({
  isOpened, onSubmit, onToggle, sizeLimit,
}) => {
  const {t} = useTranslation();

  return (
    <CustomModal openModal={isOpened} setOpenModal={onToggle} className="bg-milk rounded-xl pt-12 pb-8 w-110">
      <div className="flex flex-col items-center">
        <span className="text-center font-bold-poppins text-3xl text-dawn-gray tracking-tight mb-8 px-28">{t('modal.sizeLimit.title')}</span>
        <p className="text-center text-dawn-gray text-opacity-60 mb-12 px-20">
          {t('modal.sizeLimit.content', {sizeLimit})}
        </p>
        <Button
          className="py-4 px-24 rounded-full"
          variant="edit"
          onClick={onSubmit}
        >
          <span className="text-white">
            {t('chat.ok')}
          </span>
        </Button>
      </div>
    </CustomModal>
  );
};

export default FileSizeLimitModal;
