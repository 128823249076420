import {useTranslation} from 'react-i18next';
import {isIOS, isAndroid} from 'react-device-detect';

import {Button} from '@components/Form';
import {AppStoreIcon, GooglePlayIcon} from '@assets/icons';
import config from '@config/config';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const MobileView = ({isLandscapeAvailable}) => {
  const {t} = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if(location.pathname === '/forgot'){
      window.location.href =`allbry://forgot${location.search}`;
    }
    else if(location.pathname?.includes('/survey')){
      window.location.href =`allbry://survey${location.search}`;
    }
    else if(location.pathname?.includes('/sign-up-consumer')){
      window.location.href =`allbry://signUpConsumer?accountId=${location.pathname?.split('/')[2]}`;
    }
    else{
      window.location.href =`allbry://`;
    }
  }, [location.pathname])
  

  const IosButton = () => (
    <Button
      variant="custom"
      onClick={() => window.location.replace(config.appStoreUrl)}
      className="bg-silver-md-light rounded-lg w-48 py-3"
    >
      <AppStoreIcon width={25} height={25} />
      <span className="font-semibold text-lg text-dawn-gray ml-3">{t('mobile.appStore')}</span>
    </Button>
  );

  const AndroidButton = () => (
    <Button
      variant="custom"
      onClick={() => window.location.replace(config.googlePlayUrl)}
      className="bg-silver-md-light rounded-lg w-48 py-3"
    >
      <GooglePlayIcon width={25} height={25} />
      <span className="font-semibold text-lg text-dawn-gray ml-3">{t('mobile.googlePlay')}</span>
    </Button>
  );

  return (
    <div className="flex-1 w-screen h-screen flex flex-col items-center justify-center px-10 bg-main">
      <span className="text-center text-pale-orange text-xl font-bold">{t(isLandscapeAvailable ? 'mobile.availableInLandscape' : 'mobile.notAvailable')}</span>
      <span className="text-center text-dawn-gray mt-2 text-md">{t(isLandscapeAvailable ? 'mobile.pleaseUseLinksLandscape' : 'mobile.pleaseUseLinks')}</span>
      <div className="mt-8 flex flex-row justify-center gap-8">
        {// eslint-disable-next-line no-nested-ternary
        isIOS
          ? (<IosButton />)
          : isAndroid
            ? (<AndroidButton />)
            : (
              <>
                <IosButton />
                <AndroidButton />
              </>
            )
        }
      </div>
    </div>
  );
};

export default MobileView;
